<template>
  <div
    id="loading"
    v-if="visible"
  >
    <div class="wrapper">
      <i class="iconfont icon-jiazai"></i>
      <div class="text">{{message}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data () {
    return {
      message: "",
      visible: false
    }
  },
}
</script>

<style scoped>
#loading {
  width: 100vw;
  z-index: 1000;
  position: fixed;
  top: 100px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 1000;
}
.wrapper {
  width: 100px;
  height: 100px;
  background-color: rgba(41, 41, 41, 0.739);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapper .icon-jiazai {
  width: 40px;
  height: 40px;
  font-size: 30px;
  animation: 1.6s rotate1 infinite linear;
  margin: 0;
  padding: 0;
}

@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.text {
  text-align: center;
  height: 20px;
  width: 100%;
  font-size: 14px;
}
</style>