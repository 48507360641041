<template>
  <div id="unauth">
    <div>
      <img src="../../assets/images/401.png" alt="" srcset="" />
    </div>
    <div>
      <div>无法验证您的身份</div>
      <div>请通过合法KEY进入系统</div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import { Dialog } from "vant";

export default {
  name: "Unauth",
  data() {
    return {
      Key: ""
    };
  },
  async mounted() {
    let Key = this.$route.query?.Key;
    if (!Key) {
      Key = localStorage.getItem("Key");
    }
    try {
      const response = await axios.get(`/api/ReSend/GetAlwaysToken?key=${Key}`);
      if (response?.data?.Status === 200) {
        // 登录成功
        // 保存Key和JWT.
        // FIXME: 坑. JWT管理不用vuex, 用了localStorage
        localStorage.setItem("Key", Key);
        localStorage.setItem("Token", response?.data?.Response);
        this.$store.commit("updateAuth", true);
        this.$router.push({ name: "Home" });
      } else {
        throw new Error(response?.data?.Msg);
      }
    } catch (error) {
      Dialog.alert({
        title: "登陆失败",
        message: error?.message
      });
    }

    // --------------------------

    // // Key不存在
    // if (!Key) {
    //   Dialog.alert({
    //     title: "Key不存在",
    //     message: "请使用合法的Key进入"
    //   }).then(() => {
    //     this.$router.push({ name: "Unauth" });
    //   });
    //   return;
    // }
    // // Key存在，获取Token
    // let res;
    // try {
    //   res = await axios.get(`/api/ReSend/GetToken?key=${Key}`);
    // } catch (e) {
    //   throw new Error(e);
    // }
    // // console.log(res);

    // // 用户存在，成功获取到Token
    // if (res.data.Status === 200) {
    //   // 保存 Key 和 Token
    //   localStorage.setItem("Key", Key);
    //   let Token = res.data.Response;
    //   localStorage.setItem("Token", Token);

    //   let tokenNotExpire = await axios.post("/api/Notice/QueryNotice", {
    //     page: 0
    //   });

    //   // Token 过期了， 重新发送新的Key
    //   if (!tokenNotExpire) {
    //     // Token 没有过期
    //     return;
    //   } else {
    //     this.$store.commit("updateAuth", true);
    //     this.$router.push({ name: "Home" });
    //   }
    //   // 用户不存在， 无法获取Token
    // } else if (res.data.Status === 4001001) {
    //   Dialog.alert({
    //     title: "无效的Key",
    //     message: "请使用合法的Key进入"
    //   }).then(() => {
    //     this.$router.push({ name: "Unauth" });
    //   });
    // } else {
    //   return;
    // }
  },
  methods: {}
};
</script>

<style scoped>
#unauth {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #1a3656;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#unauth > div {
  color: #acb0b4;
  font-size: 20px;
  text-align: center;
  margin: 40px 0;
}
</style>
