<template>
  <div id="search-input">
    <div class="input-wrapper">
      <i class="iconfont icon-sousuo"></i>
      <input
        type="text"
        :placeholder="this.searchObj.content"
        v-model="searchKeyword"
        @blur="handleBlur"
        @focus="handleFocus"
        @keyup.enter="submitKeywordToSearch"
        :disabled="isSelectMode"
      >
    </div>
    <!-- 搜索历史 -->
    <div
      id="search-history"
      v-if="onfocus"
    >
      <div
        class="name"
        v-if="this.historySearchList.length !== 0"
      >搜索历史</div>
      <div id="search-history-panel">
        <ul
          class="search-history-list"
          v-if="this.historySearchList.length !== 0"
        >
          <li
            v-for="(searchItem, index) in historySearchList"
            :key="index"
            @click="submitToSearch(searchItem)"
          >
            <!-- :style="searchItem.type === 'tag' ? $utils.colorfulBack(searchItem.content) : null" -->
            <div>{{searchItem.content}}</div>
          </li>
        </ul>
        <div
          class="clear-history"
          v-if="this.historySearchList.length !== 0"
          @click.stop="clearHistorySearch"
        >清空搜索记录</div>
        <div
          class="clear-history"
          v-else
        >还没有搜索记录</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import axios from "../utils/axios"
import {Notify} from 'vant';
export default {
  name: "SearchInput",
  data () {
    return {
      placeholder: "搜索",
      searchKeyword: "",
      // searchTag: null,
      onfocus: false,
      currentPage: 0
    }
  },
  computed: mapState({
    isSelectMode: state => state.selectMode,
    historySearchList: state => state.historySearchList,
    searchObj: state => state.searchObj,
  }),
  methods: {
    handleFocus () {
      this.$store.commit("updateSearchObj", {})
      // 聚焦
      this.onfocus = true;
      this.placeholder = "";
      const searchPath = "/search";
      if (this.$route.path !== searchPath) {
        this.$router.push({path: "search"});
      }
    },
    handleBlur () {
      this.$store.commit("updateSearchObj", {content: "搜索"})
      setTimeout(() => {
        this.onfocus = false;
      })
    },
    // 添加搜索记录
    submitKeywordToSearch () {
      this.searchKeyword = this.searchKeyword.trim();
      // 关键词为空
      if (!this.searchKeyword) {
        return;
      }
      // 添加一条搜索记录
      // 关键词是否已存在
      let isHaveTheKeyword = this.historySearchList.some((o) => {
        return o.content === this.searchKeyword
      })
      if (!isHaveTheKeyword) {
        this.$store.commit("pushASearch", {
          type: "keyword", content: this.searchKeyword
        });
        // 保存搜索记录到localStorage
        let historySearch = this.$store.state.historySearchList;
        historySearch = JSON.stringify(historySearch);
        localStorage.setItem("historySearch", historySearch)
      }
      // 搜索请求
      this.submitToSearch({type: "keyword", content: this.searchKeyword})
    },
    clearHistorySearch () {
      Notify({type: 'success', message: "已清空搜索历史"});
      this.$store.commit("clearHistorySearchList");
      localStorage.setItem("historySearch", "[]");
    },
    submitToSearch (searchItem) {
      this.$loading({message: "loading"})
      // this.searchKeyword = searchItem.content;
      this.$store.commit("updateSearchObj", searchItem)
      let options = {
        pageSize: 10,
        param: {
          tag: "",
          search: ""
        }
      }

      if (searchItem.type === "tag") {
        // console.log("tag");
        options.param.tag = searchItem.content
      } else {
        // console.log("keyword");
        options.param.search = searchItem.content
      }
      axios.post("/api/Notice/QueryNotice", options)
        .then(res => {
          if (res.data.Status === 200) {
            this.onfocus = false;
            // console.log(res.data.Response.Data);
            this.$store.commit("updateSearchPage", res.data.Response.Page)
            this.$store.commit("updateSearchResultList", res.data.Response.Data)
            this.$loading.cancel()
          } else {
            // console.log("请求出错");
            Notify({type: 'warning', message: "请求失败，请检查网络连接是否正常"});
          }
        }).catch(err => {
          console.log(err);
          Notify({type: 'warning', message: "请求失败，请检查网络连接是否正常"});
        })
    },
  },
  mounted () {
    // this.searchTag = this.$route.params;
    // console.log(this.$route.params);
    // this.searchKeyword = 
  },
}
</script>

<style scoped>
#search-input {
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  position: relative;
}

.input-wrapper {
  width: 90%;
  height: 40px;
  font-size: 12px;
  color: #797979;
  position: relative;
  display: flex;
  align-items: center;
}
.input-wrapper i {
  position: absolute;
  top: 12px;
  left: 12px;
  user-select: none;
}
input {
  width: 100%;
  height: 90%;
  border: none;
  outline: none;
  background-color: #f5f5f5;
  border-radius: 20px;
  text-align: center;
}

/* 搜索历史 */
#search-history {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  font-size: 14px;
}

#search-history .name {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 10px 5%;
  background-color: #fff;
}

#search-history-panel {
  background-color: #fff;
}

#search-history-panel .search-history-list {
  padding: 0 5%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
}

.search-history-list li {
  display: inline-block;
  width: auto;
  height: 18px;
  line-height: 18px;
  padding-left: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

/* 清空搜索历史 */
.clear-history {
  height: 20px;
  padding: 10px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  user-select: none;
}
/* search-result */
#search-result {
  height: 100vh;
  margin-top: 1px;
  padding-bottom: 60px;
}

#search-result .name {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 0 5%;
  user-select: none;
}
</style>