<template>
  <div id="flight-template">
    <!-- 监控条件 -->
    <!-- <div class="block-title blue">
      监控条件
    </div>
    <div class="block-content q-mv-sm">
      <div class="pre">航段：{{ messageBody.Segment }}，监控时间：</div>
    </div> -->
    <div class="block-content q-mv-sm">
      <div class="pre">
        <span>您关注的航线 </span>
        <span style="font-weight: 600">{{ messageBody.Segment }} </span>
        <span> 有新的政策生成</span>
      </div>
    </div>
    <!-- 生成政策 -->
    <div
      v-for="(fliItem, fliIndex) in messageBody.Data"
      :key="fliIndex + 'fli'"
    >
      <div class="block-title blue q-mt-lg">
        <span>航班号: {{ fliItem.FlightNo }}</span>
        <span>时间: {{ formatDate(fliItem.PublishTime, "dts") }}</span>
      </div>
      <table class="property-table full q-mv-md">
        <thead>
          <tr>
            <th>日期</th>
            <th>建议价</th>
            <th>类型</th>
            <th>关注航班</th>
            <th>对标航班</th>
            <th>差值</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in analyzeFromMessageBody(fliItem)"
            :key="index"
          >
            <!-- 起飞日期 -->
            <td
              v-if="item.dateRow > 0"
              :rowspan="item.dateRow"
              class="text-center"
            >
              {{ item.date }}
            </td>
            <td
              v-if="item.priceRow > 0"
              class="text-center"
              :rowspan="item.priceRow"
            >
              <div class="inline-block">
                <div class="text-center inline-block">
                  {{ item.cabin }}
                </div>
                <div class="text-right q-mt-xs">{{ item.price }}元</div>
              </div>
            </td>
            <!-- 类型 -->
            <td
              v-if="item.typeRow > 0"
              :rowspan="item.typeRow"
              class="text-center"
            >
              {{ item.type }}
            </td>
            <!-- 关注航班 -->
            <td class="text-right">
              <div class="inline-block">
                <div class="text-center inline-block">{{ item.flight }}</div>
                <div class="text-right q-mt-xs">
                  <span v-if="item.type === '客座'">{{
                    item.lf !== "--" ? `${item.lf}%` : item.lf
                  }}</span>
                  <span v-else>{{
                    item.lp !== "--" ? `${item.lp}元` : item.lp
                  }}</span>
                </div>
              </div>
            </td>
            <!-- 对标航班 -->
            <td class="text-right">
              <div class="inline-block">
                <div class="text-center inline-block">
                  {{ item.t_flight }}
                </div>
                <div class="text-right q-mt-xs">
                  <span v-if="item.type === '客座'">{{
                    item.t_lf !== "--" ? `${item.t_lf}%` : item.t_lf
                  }}</span>
                  <span v-else>{{
                    item.t_lp !== "--" ? `${item.t_lp}元` : item.t_lp
                  }}</span>
                </div>
              </div>
            </td>
            <td class="text-right">
              <div class="inline-block">
                <div class="text-center inline-block">
                  <span
                    v-if="item.type === '客座'"
                    :class="{
                      'than-red': item.than < 0,
                      'than-suc': item.than > 0,
                      'than-pr': item.than == 0
                    }"
                    >{{
                      item.than !== "--" ? `${item.than}%` : item.than
                    }}</span
                  >
                  <span
                    v-else
                    :class="{
                      'than-red': item.than < 0,
                      'than-suc': item.than > 0,
                      'than-pr': item.than == 0
                    }"
                    >{{
                      item.than !== "--" ? `${item.than}元` : item.than
                    }}</span
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { dateUtils, objUtils } from "../../../utils/common";
import _ from "lodash-es";

export default {
  name: "FlightTemplateQuo",
  props: {
    messageBody: {
      type: Object,
      default: () => {}
    }
  },
  //   data() {
  //     return {
  //       list: []
  //     };
  //   },
  //   watch: {
  //     messageBody: {
  //       handler(x) {
  //         this.analyzeFromMessageBody(x);
  //       },
  //       deep: true,
  //       immediate: true
  //     }
  //   },
  methods: {
    formatDate(date, type) {
      return dateUtils.formatDate(date, type);
    },
    /**
     * 解析消息
     */
    analyzeFromMessageBody(data) {
      try {
        const list = [];
        data = this.fliterQuo(data);
        for (let i in data.Data) {
          if (!data.Data) {
            break;
          }
          const analyz = {
            date: "", //
            cabin: "", //
            price: "", //
            type: "",
            flight: "", //
            lp: "--", //
            lf: "--",
            t_flight: "", //
            t_lp: "--", //
            t_lf: "--",
            than: "--",
            dateRow: 0,
            priceRow: 0,
            typeRow: 0
          };
          // 加入政策相关信息
          analyz.date = moment(i).format("MM/DD");
          const quoData = data.Data[i].map(o => {
            return this.formatQuo(o, analyz);
          });
          const puArr = _.concat(...quoData);
          if (puArr && puArr.length > 0) {
            puArr[0].dateRow = puArr.length;
          }
          list.push(_.concat(...quoData));
        }
        return _.concat(...list);
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    /**
     * 解析政策数据
     */
    formatQuo(quoData, analyz) {
      const temp = { ...analyz };
      temp.cabin = quoData.Quotation.Cabin;
      temp.price = quoData.Quotation.Price;
      temp.flight = quoData.Quotation.FlightNo;
      const lpData = this.formatLp(quoData.MirrorImage.Lp, temp);
      if (lpData.length > 0) {
        lpData[0].typeRow = lpData.length;
      }
      const lfData = this.formatLf(quoData.MirrorImage.Lf, temp);
      if (lfData.length > 0) {
        lfData[0].typeRow = lfData.length;
      }
      const data = lpData.concat(lfData);
      if (data.length > 0) {
        data[0].priceRow = lfData.length + lpData.length;
      }
      return data;
    },
    /**
     * 解析低价数据
     */
    formatLp(lpData, analyz) {
      if (!lpData || lpData.length < 1) {
        return [];
      }
      const arr = [];
      const obj = { ...analyz, lp: "--" };
      obj.type = "低价";

      for (let i in lpData) {
        if (!lpData[i]) {
          continue;
        }
        if (lpData[i]?.FlightNo === obj.flight) {
          obj.lp =
            lpData[i].Price || lpData[i].Price === 0 ? lpData[i].Price : "--";
          break;
        }
      }
      for (let i in lpData) {
        if (!lpData[i] && i !== obj.flight) {
          const temp = {
            ...obj,
            t_flight: i,
            t_lp: "--",
            than: "--"
          };
          arr.push(temp);
        } else if (i === obj.flight) {
          continue;
        } else {
          const temp = {
            ...obj
          };
          temp.t_flight = lpData[i].FlightNo;
          temp.t_lp =
            lpData[i].Price || lpData[i].Price === 0 ? lpData[i].Price : "--";
          temp.than = this.computePr(temp.lp, lpData[i].Price);
          arr.push(temp);
        }
      }
      return arr;
    },
    /**
     * 解析客座率数据
     */
    formatLf(lfData, analyz) {
      if (!lfData || lfData.length < 1) {
        return [];
      }
      const arr = [];
      const obj = { ...analyz, lf: "--" };
      obj.type = "客座";

      for (let i in lfData) {
        if (!lfData[i]) {
          continue;
        }
        if (lfData[i]?.FlightNo === obj.flight) {
          obj.lf =
            lfData[i].Lf || lfData[i].Lf === 0
              ? _.round(lfData[i].Lf * 100, 1)
              : "--";
          break;
        }
      }
      for (let i in lfData) {
        if (!lfData[i] && i !== obj.flight) {
          const temp = {
            ...obj,
            t_flight: i,
            t_lf: "--",
            than: "--"
          };
          arr.push(temp);
        } else if (i === obj.flight) {
          continue;
        } else {
          const temp = {
            ...obj
          };
          temp.t_flight = lfData[i].FlightNo;
          temp.t_lf =
            lfData[i].Lf || lfData[i].Lf === 0
              ? _.round(lfData[i].Lf * 100, 1)
              : "--";
          temp.than = this.computePr(temp.lf, temp.t_lf);
          arr.push(temp);
        }
      }
      return arr;
    },
    /**
     * 计算差值
     */
    computePr(val1, val2) {
      try {
        const num = _.toNumber(val1) - _.toNumber(val2);
        return _.isNumber(num) && !isNaN(num) ? num : "--";
      } catch (error) {
        return "--";
      }
    },
    /**
     * 过滤快照为null 的情况
     */
    fliterQuo(data) {
      try {
        const temp = _.cloneDeep(data);
        for (let i in temp.Data) {
          temp.Data[i] = temp.Data[i].map(o => {
            return {
              ...o,
              MirrorImage: {
                ...o.MirrorImage,
                Lf: objUtils.formatRequestParams(o.MirrorImage.Lf),
                Lp: objUtils.formatRequestParams(o.MirrorImage.Lp)
              }
            };
          });
        }
        return temp;
      } catch (error) {
        console.log(error);
        return data;
      }
    }
  }
};
</script>

<style scoped>
.block-title {
  background-color: rgb(248, 248, 248);
  padding: 6px 6px;
  font-size: 14px;
}

.block-content {
  padding-left: 10px;
  /* margin: 10px 0; */
}

.block-title.gray {
  border-left: 4px solid rgb(78, 78, 78);
}

.block-title.blue {
  border-left: 4px solid #7293ff;
  display: flex;
  justify-content: space-between;
}

.property-table {
  border-collapse: collapse;
  font-size: 12px;
}

.property-table th,
.property-table td {
  border: 1px solid #c2c2c2;
  padding: 4px 4px;
}

.property-table th {
  background-color: #ececec;
  padding: 10px 4px;
}

.property-table.full {
  width: 100%;
}

.property-table.nowrap {
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.tag.blue {
  color: #0d41ea;
}

.tag.red {
  color: #ff2300;
}

.table-condition {
  font-size: 12px;
}
.table-condition th {
  text-align: right;
}

.pre {
  font-size: 16px;
}
.than-red {
  color: #ff2300;
}
.than-suc {
  color: #67c23a;
}
.than-pr {
  color: #409eff;
}
</style>
