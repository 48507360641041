<template>
  <div id="app">
    <Nav />
    <router-view></router-view>
    <!-- 工具栏 -->
    <Toolbar />
    <!-- 底部操作面板 -->
    <transition name="fade">
      <SelectPanel />
    </transition>
    <!-- 返回顶部 -->
    <transition name="fade">
      <Backtop />
    </transition>
  </div>
</template>

<script>
import Nav from "./components/Nav";
import SelectPanel from "./components/SelectPanel";
import Toolbar from "./components/Toolbars";
import Backtop from "./components/Backtop";

export default {
  name: "App",
  components: {
    Nav,
    SelectPanel,
    Toolbar,
    Backtop
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style>
html {
  color: #1d1d1f;
  /* scroll-behavior: smooth; */
}
body {
  overscroll-behavior-y: none;
  background-color: #f5f5f5;
}
/* #app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
} */
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  color: #1d1d1f;
}
#app {
  width: 100vw;
  height: 100vh;
  font-size: 14px;
}
/* 身份认证未通过 */
#unauth {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
}
/* 动画 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
