import axios from "axios";

import { Dialog } from "vant";

const service = axios.create({
  baseURL: "/",
  timeout: 60000
});

// 请求拦截器
service.interceptors.request.use(
  function(config) {
    if (config.url.includes("/api/ReSend")) {
      return config;
    }
    let Token = localStorage.getItem("Token");
    config.headers.Authorization = "Bearer " + Token;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    if (JSON.stringify(error).includes("401")) {
      reSend();
    }
  }
);

async function reSend() {
  let Key = localStorage.getItem("Key");
  let res = await service.get(`/api/ReSend/ReSendNotice?key=${Key}`);
  if (res.data.Status === 200) {
    Dialog.alert({
      title: "Key已过期",
      message: "新的Key已发送，请到微信查看"
    }).then(() => {
      location.pathname = "/";
      return;
    });
  }
}

export default service;
