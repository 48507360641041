import moment from "moment";
import _ from "lodash-es";

const dateUtils = {
  /**
   * 日期合法校验
   * @param {String} date
   */
  isDateValid(date) {
    return moment(date).isValid();
  },

  /**
   * 格式化日期
   * @param {Date|String} date
   * @param {String} type
   */
  formatDate(date, type = "d") {
    if (
      !dateUtils.isDateValid(date) ||
      date === undefined ||
      date === null ||
      date === ""
    ) {
      return null;
    }
    if (type === "dtl") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss.SSS");
    } else if (type === "dt") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    } else if (type === "d") {
      return moment(date).format("YYYY-MM-DD");
    } else if (type === "dts") {
      return moment(date).format("YYYY-MM-DD HH:mm");
    } else if (type === "t") {
      return moment(date).format("HH:mm:ss");
    } else if (type === "ts") {
      return moment(date).format("HH:mm");
    } else if (type === "ym") {
      return moment(date).format("YYYY-MM");
    } else if (type === "md") {
      return moment(date).format("M/D");
    } else if (type === "s") {
      return moment(date).format("YY/M/D");
    } else if (type === "sf") {
      return moment(date).format("YY/M/D HH:mm");
    }
  }
};

/**
 * 对象工具
 */
const objUtils = {
  /**
   * 格式化请求参数
   *
   * 排除所有为 null || '' || undefined 的属性
   *
   * @param {Object} params
   */
  formatRequestParams(params) {
    const r = {};
    for (const key in params) {
      if (
        params[key] !== null &&
        params[key] !== "" &&
        params[key] !== undefined
      ) {
        r[key] = params[key];
      }
    }
    return r;
  },

  /**
   * 将对象属性转换为小写
   * @param {Object} data
   */
  formatObjLowerCase(data) {
    let obj = {};
    for (const attr in data) {
      obj = {
        ...obj,
        [_.lowerFirst(attr)]: data[attr]
      };
    }
    return obj;
  },
  /**
   * String分割为Array
   */
  str2Arr(str) {
    const arr = str.replace(/，/g, ",").split(",");
    return arr.filter(o => o !== "" && o !== null).map(o => o.trim());
  },

  /**
   * str是否为null或''或undefined
   */
  isStrNil(str) {
    return str === "" || str === null || str === undefined;
  }
};
export { dateUtils, objUtils };
