<template>
  <div id="details">
    <div id="msg-header">
      <div class="title">{{ message.Title }}</div>
      <div class="date">
        <i class="iconfont icon-shizhong"></i>
        <span>{{ message.NoticeTime }}</span>
      </div>
      <Tags :tags="message.Tags" :wrap="true" />
    </div>
    <div id="msg-body">
      <article>
        <FlightTemplate
          v-if="message.NoticeType === 100"
          :messageBody="messageBody"
        />
        <flight-template-change
          v-if="message.NoticeType === 200"
          :data="messageBody"
        ></flight-template-change>
        <flight-template-quo
          ref="flightTemplateQuo"
          v-if="message.NoticeType === 400"
          :messageBody="messageBody"
        ></flight-template-quo>
        <flight-template-delay
          ref="flightTemplateDelay"
          v-if="message.NoticeType === 500"
          :data="messageBody"
        ></flight-template-delay>
      </article>
    </div>
  </div>
</template>

<script>
import Tags from "../../components/Tags";
import axios from "../../utils/axios";
import FlightTemplate from "./templates/FlightTemplate";
import { Notify } from "vant";
import FlightTemplateChange from "./templates/FlightTemplateChange.vue";
import FlightTemplateQuo from "./templates/FlightTemplateQuo.vue";
import FlightTemplateDelay from './templates/Flight-template-delay.vue'

export default {
  name: "Details",
  data() {
    return {
      // noticeResource: [
      //   {100: "flight"}
      // ],
    };
  },
  components: {
    Tags,
    FlightTemplate,
    FlightTemplateChange,
    FlightTemplateQuo,
    FlightTemplateDelay
  },
  computed: {
    // currentKey () {
    //   return this.$route.params.key;
    // },
    message() {
      return this.$route.params.message;
    },
    messageBody() {
      return JSON.parse(this.message.Message);
    }
  },
  methods: {
    markRead() {
      axios
        .post("/api/Notice/Read", [this.message.Key])
        .then(res => {
          if (res.data.Status === 200) {
            // 同步
            this.$store.commit("markRead", [this.message.Key]);
            this.$store.commit("markReadInUnreadList", [this.message.Key]);
            this.$store.commit("markReadInSearchResult", [this.message.Key]);
          } else {
            Notify({ type: "warning", message: "网络异常" });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  updated() {
    this.markRead();
  },
  mounted() {
    this.markRead();
  }
};
</script>

<style scoped>
#details {
  /* margin-top: 60px; */
  background-color: #f2f2f2;
}
#msg-header {
  padding: 20px 6%;
  background-color: #fff;
  margin: 1px 0;
}
#msg-header .title {
  font-size: 20px;
  font-weight: 600;
}
#msg-header .date {
  height: 20px;
  line-height: 20px;
  margin: 6px 0;
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
}
#msg-header .date i {
  font-size: 14px;
  margin-right: 6px;
}
/* 消息正文 */
#msg-body {
  background-color: #fff;
  width: 100vw;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
  padding: 20px 6%;
  /* font-size: 16px; */
  /* line-height: 25px; */
  padding-bottom: 200px;
}
</style>
