import PopconfirmComponent from "../components/Popconfirm";

const Popconfirm = {};

Popconfirm.install = function(Vue) {

  const PopconfirmConstructor = Vue.extend(PopconfirmComponent);
  const instance = new PopconfirmConstructor();

  instance.$mount(document.createElement('div'))
  document.body.appendChild(instance.$el);

  /**
   * 确认框
   * @param {*} options 确认框实例参数 
   * @param {*} confirmCallback 确定回调
   * @param {*} cancelCallback 取消回调
   */
  Vue.prototype.$popconfirm = function (options, confirmCallback, cancelCallback) {
    let {message = "", cancel = "取消", confirm = "确定"} = options;
    instance.message = message;
    instance.cancel = cancel;
    instance.confirm = confirm;
    instance.confirmCallback = confirmCallback;
    instance.cancelCallback = cancelCallback;
    instance.visible = true;
  }

  Vue.prototype.$popconfirm.cancel = function() {
    instance.visible = false;
  }

  Vue.prototype.$popconfirm.confirm = function() {
    instance.visible = false;
    instance.confirmCallback();
  }
}
export { Popconfirm };