<template>
  <div id="search" :class="{ 'select-mode': isSlectMode }">
    <!-- 搜索结果 -->
    <ul :id="touchElId">
      <li
        v-for="message in searchResultList"
        :key="message.Key + Math.random()"
      >
        <Message :theMessage="message" />
      </li>
      <!-- 未找到相关结果 -->
      <div class="no-result" v-if="searchResultList.length === 0">
        未找到相关结果
      </div>
    </ul>
    <!-- 上拉加载 -->
    <LoadMore :touchElId="touchElId" :loadFunction="pullUpLoad" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Message from "../../components/Message";
import axios from "../../utils/axios";
import LoadMore from "../../components/LoadMore";
import { Notify } from "vant";

export default {
  name: "Search",
  components: {
    Message,
    LoadMore
  },
  data() {
    return {
      touchElId: "search-result-list",
      scroll: 0,
      timeOut: null,
      loading: false
    };
  },
  mounted() {
    this.searchTag = this.$route.params;

    // 从 localStorage 获取 historySearch 初始化 vuex
    this.initHistorySearch();
  },
  computed: mapState({
    isSlectMode: state => state.selectMode,
    searchResultList: state => {
      return state.searchResultList;
    },
    searchObj: state => {
      return state.searchObj;
    },
    currentSearchResultPage: state => {
      return state.currentSearchResultPage;
    }
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      window.addEventListener("scroll", vm.handleScroll);
    });
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  },
  methods: {
    handleScroll() {
      if (this.loading) {
        return;
      }
      const scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      const clen = document.documentElement.clientHeight;
      const of = document.getElementById(this.touchElId).clientHeight;
      const hei = scroll + clen - 120 > of - 10;

      // 上划不加载 距离底部10px加载
      if (this.scroll > scroll || !hei) {
        return;
      }
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.loading = true;
      this.timeOut = setTimeout(async () => {
        await this.pullUpLoad();
        this.loading = false;
        this.scroll = scroll;
      }, 300);
    },
    async pullUpLoad() {
      if (this.currentSearchResultPage === 0) {
        return;
      }
      let options = {
        page: this.currentSearchResultPage + 1,
        pageSize: 20,
        param: {
          tag: "",
          search: ""
        }
      };
      if (this.searchObj.type === "tag") {
        options.param.tag = this.searchObj.content;
      } else {
        options.param.search = this.searchObj.content;
      }
      axios
        .post("/api/Notice/QueryNotice", options)
        .then(res => {
          let length = res.data.Response.Data.length;
          let currentPage = res.data.Response.Data.length;
          let data = res.data.Response.Data;
          if (length === 0) {
            // 主要通知
            Notify({ type: "primary", message: "搜索不到更多结果了" });
          }
          this.$store.commit("updateSearchPage", currentPage);
          this.$store.commit("loadMoreSearchResult", data);
        })
        .catch(err => {
          // 请求失败
          Notify({
            type: "warning",
            message: "加载失败，请检查网络连接是否正常"
          });
          throw new Error(err);
        });
    },
    initHistorySearch() {
      let historySearch;
      try {
        historySearch = localStorage.getItem("historySearch");
      } catch (err) {
        console.log(err);
      }
      if (historySearch) {
        historySearch = JSON.parse(historySearch);
        this.$store.commit("initHistorySearchList", historySearch);
      }
    }
  }
};
</script>

<style>
#search {
  background-color: #f2f2f2;
  margin-top: 60px;
  transition: margin 0.3s ease;
  min-height: calc(100vh - 60px);
}
#search.select-mode {
  margin-top: 0;
}
#search-result-list {
  background-color: #f2f2f2;
  width: 100vw;
  min-height: calc(100vh - 60px);
}
.no-result {
  height: 60px;
  line-height: 60px;
  text-align: center;
}
</style>
