import VueRouter from "vue-router";

// import Login from "../view/login";

import Home from "../view/home";
import Search from "../view/search";
import Details from "../view/details";
import Unread from "../view/unread";
import Unauth from "../view/unauth";

import store from "../store";

const routes = [
  // {
  //   path: "/",
  //   name: "Login",
  //   component: Login,
  //   meta: {
  //     auth: false,
  //   },
  // },
  {
    path: "/",
    name: "Unauth",
    // redirect: "/",
    alias: "/unauth",
    component: Unauth,
    meta: {
      auth: false,
      position: {
        x: 0,
        y: 0
      }
    }
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      auth: true,
      position: {
        x: 0,
        y: 0
      }
    }
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      auth: true,
      position: {
        x: 0,
        y: 0
      }
    }
  },
  {
    path: "/details/:key",
    name: "Details",
    component: Details,
    meta: {
      auth: true,
      position: {
        x: 0,
        y: 0
      }
    }
  },
  {
    path: "/unread",
    name: "Unread",
    component: Unread,
    meta: {
      auth: true,
      position: {
        x: 0,
        y: 0
      }
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const scrollBehavior = function(to) {
  if (to.name === "Home" || to.name === "Search" || to.name === "Unread") {
    return { x: 0, y: to.meta.position.y };
  }
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: "history",
  routes,
  base: "/",
  scrollBehavior
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    if (!store.state.auth) {
      next({ name: "Unauth" });
    } else {
      // 保存跳转的历史记录
      saveHistory(to, from);
      // 保存页面的位置信息
      let top =
        document.body.scrollTop ||
        document.documentElement.scrollTop ||
        window.pageYOffset;
      if (
        from.name === "Home" ||
        from.name === "Search" ||
        from.name === "Unread"
      ) {
        from.meta.position.y = top;
      }
      next();
    }
  } else {
    next();
  }
});

function saveHistory(to, from) {
  // 保存跳转的历史记录
  let _history;
  try {
    _history = JSON.parse(localStorage.getItem("_history"));
  } catch (e) {
    console.log(e);
  }
  if (!_history) {
    _history = [];
  }

  if (to.name === "Details" && from.name === "Details") {
    localStorage.setItem("_history", JSON.stringify(_history));
  } else if (to.name === "Details" && !from.name) {
    localStorage.setItem("_history", JSON.stringify(_history));
  } else if (to.name === "Home") {
    _history = ["Home"];
    localStorage.setItem("_history", JSON.stringify(_history));
  } else {
    _history.push(to.name);
    localStorage.setItem("_history", JSON.stringify(_history));
  }
  //  console.log(_history);
}
export default router;
