import LoadingComponent from "../components/Loading"

// 消息提示插件
const Loading = {};

// 消息提示
Loading.install = function(Vue) {
  const LoadingCustructor = Vue.extend(LoadingComponent);
  const instance = new LoadingCustructor();

  instance.$mount(document.createElement("div"))
  document.body.appendChild(instance.$el);

  Vue.prototype.$loading = function(options) {
    const {message = "loading"} = options;
    instance.message = message;
    instance.visible = true;
  }

  Vue.prototype.$loading.cancel = function(duration = 600) {
    setTimeout(() => {
      instance.visible = false;
    }, duration)
  }
}

export {Loading};