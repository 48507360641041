<template>
  <ul id="tags" :class="flexWrap ? 'flex-wrap' : ''">
    <li
      v-for="(tag, index) in tagsList"
      :key="index"
      @click="searchByTag($event, tag)"
    >
      {{ tag }}
    </li>
    <!-- :style="$utils.colorfulBack(tag)" -->
  </ul>
</template>

<script>
// import axios from "axios"
import axios from "../utils/axios";
import { Notify } from "vant";

export default {
  name: "Tags",
  props: ["tags", "wrap"],
  data() {
    return {};
  },
  computed: {
    tagsList() {
      return this.tags;
    },
    flexWrap() {
      return this.wrap;
    },
    isSelectMode() {
      return this.$store.state.selectMode;
    },
    historySearchList() {
      return this.$store.state.historySearchList;
    }
  },
  methods: {
    searchByTag(e, tag) {
      // 阻止冒泡
      if (e.stopPropagation) {
        !this.isSelectMode && e.stopPropagation();
      } else {
        !this.isSelectMode && (e.cancelBubble = true);
      }
      // 如果在批量选择模式则不可跳转
      if (this.isSelectMode) {
        return;
      }
      let searchObj = { type: "tag", content: tag };
      // 添加一条搜索记录
      // 关键词是否已存在
      let isHaveTheKeyword = this.historySearchList.some(o => {
        return o.type === "tag" && o.content === tag;
      });
      if (!isHaveTheKeyword) {
        this.$store.commit("pushASearch", searchObj);
        // 保存搜索记录到localStorage
        let historySearch = this.$store.state.historySearchList;
        historySearch = JSON.stringify(historySearch);
        localStorage.setItem("historySearch", historySearch);
      }
      const currentPageName = this.$route.name;

      // 如果不是在搜索页面则跳到搜索页

      if (currentPageName !== "Search") {
        this.$router.push({ name: "Search", params: searchObj });
        this.submitSearchTag(searchObj);
      } else {
        // 如果已经在搜索页则不跳转
        this.submitSearchTag(searchObj);
        return;
      }
    },
    submitSearchTag(searchObj) {
      this.$loading({ message: "loading" });
      this.$store.commit("updateSearchObj", searchObj);
      let options = {
        pageSize: 20,
        param: {
          tag: searchObj.content
        }
      };
      axios
        .post("/api/Notice/QueryNotice", options)
        .then(res => {
          if (res.data.Status === 200) {
            let data = res.data.Response.Data;
            this.$store.commit("updateSearchPage", res.data.Response.Page);
            this.$store.commit("updateSearchResultList", data);
            this.$loading.cancel();
          } else {
            Notify({
              type: "warning",
              message: "请求失败，请检查网络连接是否正常"
            });
          }
        })
        .catch(err => {
          console.log(err);
          Notify({
            type: "warning",
            message: "请求失败，请检查网络连接是否正常"
          });
        });
    }
  }
};
</script>

<style scoped>
#tags {
  /* display: inline-flex; */
  display: inline-block;
  user-select: none;
  max-width: 100%;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
}
#tags.flex-wrap {
  flex-wrap: wrap;
  height: auto;
}
#tags.flex-wrap li {
  margin: 10px 10px 4px 0;
}
#tags li {
  display: inline-block;
  width: auto;
  height: 18px;
  line-height: 18px;
  margin-right: 10px;
  margin-bottom: 4px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 0 10px;
  color: #666;
  font-size: 12px;
  user-select: none;
}
</style>
