<template>
  <div
    id="message"
    @click="handleClick"
    :class="{ checked: isChecked, 'select-mode': isSelectMode }"
    @touchstart="handleTouchstart"
    @touchmove="handleTouchmove"
    @touchend="handleTouchend"
  >
    <div class="unread" v-if="message.IsRead === 100"></div>
    <!-- 消息 -->
    <div id="message-wrapper" ref="messageWrapper">
      <!-- 消息上部分 -->
      <div class="top-wrapper" :class="isSelectMode">
        <div class="top-right-wrapper">
          <div class="title-n-time">
            <div class="title">{{ message.Title }}</div>
            <div class="time">{{ $utils.dateFromNow(message.NoticeTime) }}</div>
          </div>
          <div v-if="message.NoticeType === 100" class="abstract">
            {{ abstract }}
          </div>
          <div v-else-if="message.NoticeType === 200" class="abstract">
            {{ changeAbstract }}
          </div>
          <div v-else-if="message.NoticeType === 400" class="abstract">
            {{ quoAbstract }}
          </div>
          <div v-else-if="message.NoticeType === 500" class="abstract">
            {{ delayAbstract }}
          </div>
        </div>
      </div>
      <!-- 消息下部分 -->
      <div class="bottom-wrapper">
        <Tags :tags="message.Tags" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Tags from '../components/Tags';

export default {
  name: 'Message',
  components: {
    Tags,
  },
  props: ['theMessage'],
  data() {
    return {
      message: this.theMessage,
      timeout: null,
    };
  },
  computed: {
    isSelectMode() {
      return this.$store.state.selectMode;
    },
    isChecked() {
      return this.$store.getters.selectedMessageKeyList.find((messageKey) => {
        return messageKey === this.message.Key;
      });
    },
    // messageBody () {
    //   return JSON.parse(this.message.Message)
    // },
    abstract() {
      // console.log(this.message.Message);
      let body = JSON.parse(this.message.Message);
      let msg = body.msg;
      let planTime = body.planTime;
      let flightDate = msg[0].flightDate;
      const dep = body.dep;
      const arr = body.arr;

      let template;
      if (msg[0].lf.length > 0) {
        let flightNo = msg[0].lf[0].flightNo;
        let compareFlightNo = msg[0].lf[0].compareFlightNo;
        let lf = msg[0].lf[0].lf;
        template = `
          航段：${dep}${arr},
          起飞日期：${flightDate},
          航班号：${flightNo},
          对标航班： ${compareFlightNo},
          客座率：${lf}%,
          计划采集时间：${planTime}
        `;
      } else if (msg[0].lp.length > 0) {
        let flightNo = msg[0].lp[0].flightNo;
        let compareFlightNo = msg[0].lp[0].compareFlightNo;
        let lp = msg[0].lp[0].lp;
        template = `
          航段：${dep}${arr},
          起飞日期：${flightDate},
          航班号：${flightNo},
          对标航班： ${compareFlightNo},
          最低价：${lp},
          计划采集时间：${planTime}
          `;
      } else if (msg[0].rb.length > 0) {
        let flightNo = msg[0].rb[0].flightNo;
        let compareFlightNo = msg[0].rb[0].compareFlightNo;
        let rb = msg[0].rb[0].rb;

        template = `
          航段：${dep}${arr},
          起飞日期：${flightDate},
          航班号：${flightNo},
          对标航班： ${compareFlightNo},
          上客人数：${rb},
          计划采集时间：${planTime}
          `;
      } else {
        template = ``;
      }

      return template;
    },
    changeAbstract() {
      try {
        const data = JSON.parse(this.message.Message);
        let str = '';
        str += `航段:${data.Dep}${data.Arr},`;
        // str += `起飞日期:${data.}`
        if (data.Cancel) {
          for (let i = 0; i < data.Cancel.length; i++) {
            str += `${moment(data.Cancel[i].FlightDate).format('MM-DD')} ${
              data.Cancel[i].FlightNo
            } 航班取消; `;
          }
        }
        if (data.Recovery) {
          for (let i = 0; i < data.Recovery.length; i++) {
            str += `${moment(data.Recovery[i].FlightDate).format('MM-DD')} ${
              data.Recovery[i].FlightNo
            } 航班恢复; `;
          }
        }
        if (data.FlightChange) {
          for (let i = 0; i < data.FlightChange.length; i++) {
            str += `${moment(data.FlightChange[i].FlightDate).format(
              'MM-DD'
            )} ${data.FlightChange[i].FlightNo} 航班变更; `;
          }
        }
        return str;
      } catch (error) {
        return '';
      }
    },
    quoAbstract() {
      try {
        const data = JSON.parse(this.message.Message);
        let str = '';
        str += `航段: ${data.Segment},`;

        for (let fli = 0; fli < data.Data.length; fli++) {
          for (let i in data.Data[fli].Data) {
            let cstr = `日期: ${moment(i).format('MM/DD')} `;
            data.Data[fli].Data[i].map((o) => {
              cstr += `${o.Quotation.FlightNo} ${o.Quotation.Cabin} ${o.Quotation.Price}元; `;
            });
            str += cstr;
          }
        }

        return str;
      } catch (error) {
        console.log(error);
        return '';
      }
    },
    delayAbstract() {
      try {
        const data = JSON.parse(this.message.Message);
        let str = '';

        for (let i = 0; i < data.length; i++) {
          str += `航段: ${data[i].Dep}${data[i].Arr}, `;
          str += `起飞日期: ${moment(data[i].FlightDate).format(
            'YYYY-MM-DD'
          )}; `;
          for (let j = 0; j < data[i].DelayFlights.length; j++) {
            str += `${data[i].DelayFlights[j].DelayFlightNo}延误 ${
              data[i].DelayFlights[j].DelayTime
            }分钟,预计${moment(data[i].DelayFlights[j].EstTakeoffTime).format(
              'HH:mm'
            )}起飞;`;
          }
        }
        return str;
      } catch (error) {
        return '';
      }
    },
  },
  methods: {
    handleClick() {
      if (this.isSelectMode) {
        this.toggleCheckTheMessage();
      } else {
        this.goToMessageDetails();
      }
    },
    goToMessageDetails() {
      // 如果在选择状态，则不启用这个方法
      if (this.isSelectMode) {
        return;
      }
      this.$router.push({
        name: 'Details',
        params: { key: this.message.Key, message: this.message },
      });
    },
    toggleCheckTheMessage() {
      // 如果不是选择状态，则不启用这个方法
      if (!this.isSelectMode) {
        return;
      }
      if (!this.isChecked) {
        this.$store.commit('selectAMessage', this.message);
      } else {
        this.$store.commit('unselectAMessage', this.message);
      }
    },
    handleTouchstart() {
      //
      if (this.isSelectMode) {
        return;
      }
      let timeout = setTimeout(() => {
        this.$store.commit('checkOutSelectMode');
        this.$store.commit('selectAMessage', this.message);
      }, 800);
      this.timeout = timeout;
    },
    handleTouchmove() {
      clearTimeout(this.timeout);
    },
    handleTouchend() {
      clearTimeout(this.timeout);
    },
  },
};
</script>

<style scoped>
#message {
  width: 100%;
  /* height: 130px; */
  box-sizing: border-box;
  border-top: 1px solid #f2f2f2;
  padding: 0 3%;
  border-left: 5px solid rgba(0, 140, 255, 0);
  border-right: 5px solid rgba(0, 140, 255, 0);
  background-color: #fff;
  font-size: 14px;
  user-select: none;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  transition: border 1s ease;
  z-index: 50;
}
#message:active {
  background-color: #f2f2f2;
}
#message.select-mode {
  border-left: 5px solid rgb(195, 195, 195);
}
/* 选择消息 */
/* #message.select-mode {
  background-color: rgba(58, 163, 233, 0.465);
} */
#message.checked {
  border-left: 5px solid rgb(0, 140, 255);
  background-color: #d0e8ff;
}

#message-wrapper {
  box-sizing: border-box;
  width: calc(100vw);
  padding: 0 1rem;
  /* position: absolute; */
  left: 0;
  transition: 0.2s;
  margin-top: 10px;
  margin-bottom: 10px;
}
.top-wrapper {
  display: flex;
  margin-bottom: 10px;
}

@keyframes enlarge {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
/* 多选框 */
.checkbox .check img {
  width: 100%;
  height: 100%;
  animation: 0.3s enlarge;
}
/* 未读标识 */
.top-right-wrapper {
  flex: 1 1 auto;
}
.title-n-time {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 0.2rem;
  max-width: 75%;
  max-height: 24px;
  line-height: 24px;
  /* 多行打点 */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.time {
  font-size: 12px;
  color: #999;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
}
/* 摘要 */
.abstract {
  font-size: 14px;
  line-height: 25px;
  color: #666;
  max-height: 50px;

  /* 多行打点 */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* 消息底部标签*/
.bottom-wrapper {
  /* display: inline; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* 超出隐藏 */
  overflow: hidden;
}
ul.tags {
  display: flex;
}
.tags li {
  width: auto;
  height: 20px;
  margin-right: 10px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  border-radius: 6px;
  padding: 0 10px;
  color: #333;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}
.unread {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: skyblue;
  position: absolute;
  left: 8px;
  top: 18px;
}
</style>
