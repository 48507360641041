<template>
  <div
    id="nav"
    v-if="currentPage.enName !== 'Details' && !isSelectMode && scrollTop < 60"
    :style="navStyle"
  >
    <!-- 页面名 -->
    <div class="page-name">
      <SearchInput />
    </div>
  </div>
</template>

<script>
import SearchInput from "../components/SearchInput"
export default {
  name: "Nav",
  components: {
    SearchInput,
  },
  data () {
    return {
      Pages: [
        {
          name: "消息",
          path: "/",
          options: {
            left: "首页",
            right: null
          }
        },
        {
          name: "搜索",
          path: "/search",
          options: {
            left: "搜索",
            right: "返回"
          }
        },
        {
          name: "详情",
          path: "/details",
          options: {
            left: "返回",
            right: "返回"
          },
          style: {
            color: "#F24747"
          }
        },
      ],
      cancelBtnStyle: {
        color: '#02A7F0'
      },
      scrollTop: 0,
    }
  },
  computed: {
    curPageIndex () {
      let curPagePath = this.$route.path.split("/")[1];
      let curPageIndex = this.Pages.findIndex(o => {
        return o.path.includes(curPagePath);
      })
      return curPageIndex;
    },
    currentPage () {
      let enName = this.$route.name;
      let cunrrent = this.Pages[this.curPageIndex];
      return {enName, ...cunrrent};
    },
    isSelectMode () {
      return this.$store.state.selectMode;
    },
    navStyle () {
      return {
        opacity: (10 / this.scrollTop)
      }
    }
  },
  mounted () {
    this.listenScrollBar()
  },
  methods: {
    listenScrollBar () {
      window.addEventListener("scroll", () => {
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
        if (scrollTop < 1) {
          this.scrollTop = 10;
        } else {
          this.scrollTop = scrollTop;
        }

      })
    }
  },
}
</script>

<style scoped>
#nav {
  width: 100%;
  height: 61px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  border-bottom: 1px solid #f5f5f5;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#nav.select-mode {
  background-color: rgb(2, 175, 255);
}
.page-name {
  flex: 1 1 auto;
}
.left-options,
.right-options {
  font-size: 14px;
  width: 60px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  user-select: none;
  flex: 0 0 60px;
  border-radius: 4px;
}
.left-options {
  top: 0;
  left: 0;
}
.right-options {
  top: 0;
  right: 0;
}
.btn:active {
  background-color: #f2f2f2;
}
</style>