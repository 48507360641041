import md5 from "md5";

function colorfulBack (payload) {
  const colorfulMap = new Map([
    ['0', "[81, 122, 0]"],
    ['1', "[63, 43, 91]"],
    ['2', "[177, 84, 21]"],
    ['3', "[191, 27, 0]"],
    ['4', "[47, 87, 94]"],
    ['5', "[73, 122, 60]"],
    ['6', "[255, 90, 120]"],
    ['7', "[30, 114, 184]"],
    ['8', "[112, 93, 160]"],
    ['9', "[178, 64, 162]"],
  ])
  // 取第一个数字来确定背景颜色
  let regex = /\d/g;
  let md5Str = md5(payload);
  let firstNumber = regex.exec(md5Str);
  let rgbArr = colorfulMap.get(firstNumber[0]);
  rgbArr = JSON.parse(rgbArr);
  let bgColor = `rgba(${rgbArr[0] + 140}, ${rgbArr[1] + 100}, ${rgbArr[2] + 160}, 1)`;
  // 根据背景颜色选择黑或白色的文字颜色
  let textColor = 0.213 * rgbArr[0] + 0.715 * rgbArr[1] + 0.072 * rgbArr[2] > 255 / 2;
  textColor = textColor ? '#000000' : '#ffffff';
  return {
    backgroundColor: bgColor,
    color: textColor
  }
}

export default colorfulBack;