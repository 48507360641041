<template>
  <div id="flight-template">
    <div class="block-content q-mv-sm">
      <div class="pre">
        <span>您关注的航线 </span>
        <span style="font-weight: 600">{{ data.Dep }}-{{ data.Arr }} </span>
        <span> 发生航变</span>
      </div>
    </div>
    <!-- 预警报告 -->
    <div
      v-if="data.Cancel && data.Cancel.length"
      class="block-title blue q-mt-md"
    >
      航班取消
    </div>
    <table
      v-if="data.Cancel && data.Cancel.length"
      class="property-table full q-mv-md"
    >
      <thead>
        <tr>
          <th>起飞</th>
          <th>航班号</th>
          <th>起飞</th>
          <th>类型</th>
          <th>变更时间</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report, idx) in data.Cancel" :key="idx">
          <td class="text-center">
            {{ formatDate(report.FlightDate, "md") }} {{getDayByDate(report.FlightDate)}}
          </td>
          <td class="text-center">
            <div class="inline-block">
              {{ report.FlightNo }}
            </div>
          </td>
          <td class="text-center">
            <div class="inline-block">
              {{ formatDate(report.DepTime, "ts") }}
            </div>
          </td>
          <td class="text-center">取消</td>
          <td class="text-center">{{ formatDateGather(report.GatherTime) }}</td>
        </tr>
      </tbody>
    </table>
    <!-- 航班恢复 -->
    <div
      v-if="data.Recovery && data.Recovery.length"
      class="block-title blue q-mt-md"
    >
      航班恢复
    </div>
    <table
      v-if="data.Recovery && data.Recovery.length"
      class="property-table full q-mv-sm"
    >
      <thead>
        <tr>
          <th>起飞</th>
          <th>航班号</th>
          <th>起飞</th>
          <th>类型</th>
          <th>变更时间</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report, idx) in data.Recovery" :key="idx">
          <td class="text-center">
            {{ formatDate(report.FlightDate, "md") }}
          </td>
          <td class="text-center">
            <div class="inline-block">
              {{ report.FlightNo }} 一
            </div>
          </td>
          <td class="text-center">
            <div class="inline-block">
              {{ formatDate(report.DepTime, "ts") }}
            </div>
          </td>
          <td class="text-center">恢复</td>
          <td class="text-center">{{ formatDateGather(report.GatherTime) }}</td>
        </tr>
      </tbody>
    </table>
    <!-- 航班变更 -->
    <div
      v-if="data.FlightChange && data.FlightChange.length"
      class="block-title blue q-mt-md"
    >
      航班变更
    </div>
    <table
      v-if="data.FlightChange && data.FlightChange.length"
      class="property-table full q-mv-sm"
    >
      <thead>
        <tr>
          <th>起飞</th>
          <th>航班号</th>
          <th>起飞</th>
          <th>类型</th>
          <th>变更时间</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report, idx) in data.FlightChange" :key="idx">
          <td class="text-center">
            {{ formatDate(report.FlightDate, "md") }}
          </td>
          <td class="text-center">
            <div class="inline-block">
              {{ report.FlightNo }}
            </div>
          </td>
          <td class="text-center">
            <div class="inline-block">
              <span class="before-time">{{
                formatDate(report.BeforeTime, "ts")
              }}</span>
              <br />
              <span class="after-time">{{
                formatDate(report.AfterTime, "ts")
              }}</span>
            </div>
          </td>
          <td class="text-center">变更</td>
          <td class="text-center">{{ formatDateGather(report.GatherTime) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { dateUtils } from "../../../utils/common";

export default {
  name: "FlightTemplate",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  methods: {
    formatDate(date, type = "d") {
      return dateUtils.formatDate(date, type);
    },
    formatDateGather(data) {
      return moment(data).format("MM/DD HH:mm");
    },
    getDayByDate(date){
      const DAYS_CHN = ['日', '一', '二', '三', '四', '五', '六']
      return DAYS_CHN[moment(date).day()]
    }
  }
};
</script>

<style scoped>
.block-title {
  background-color: rgb(248, 248, 248);
  padding: 3px 6px;
  font-size: 14px;
}

.block-content {
  padding-left: 10px;
  /* margin: 10px 0; */
}

.block-title.gray {
  border-left: 4px solid rgb(78, 78, 78);
}

.block-title.blue {
  border-left: 4px solid #7293ff;
}

.property-table {
  border-collapse: collapse;
  font-size: 12px;
}

.property-table th,
.property-table td {
  border: 1px solid #c2c2c2;
  padding: 6px 4px;
}

.property-table th {
  background-color: #ececec;
  padding: 10px 4px;
}

.property-table.full {
  width: 100%;
}

.property-table.nowrap {
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.tag.blue {
  color: #0d41ea;
}

.tag.red {
  color: #ff2300;
}

.table-condition {
  font-size: 12px;
}
.table-condition th {
  text-align: right;
}

.pre {
  font-size: 16px;
  font-weight: 500;
}
.before-time {
  color: #9e9e9e;
  text-decoration: line-through;
}
.after-time {
  color: #409eff;
}
</style>
