<template>
  <div id="toolbar">
    <ul>
      <li
        @click="handleClickFirstBtn"
        v-if="currentPage.options.first"
        :class="{
          disable:
            !hasMessage &&
            (currentPage.name === 'Home') | (currentPage.name === 'Unread')
        }"
      >
        <div><i :class="currentPage.classes[0]"></i></div>
        <div>{{ currentPage.options.first }}</div>
      </li>
      <li
        @click="handleClickSecondBtn"
        v-if="currentPage.options.second"
        :style="currentPage.secondStyle"
        :class="{
          disable:
            unreadCount < 1 &&
            (currentPage.name === 'Home')
              | (currentPage.name === 'Unread')
              | (currentPage.name === 'Search')
        }"
      >
        <div><i :class="currentPage.classes[1]"></i></div>
        <div>{{ currentPage.options.second }}</div>
      </li>
      <li @click="handleClickThirdBtn" v-if="currentPage.options.third">
        <div class="icon">
          <div
            class="unread-count"
            v-if="currentPage.name === 'Home' && unreadCount > 0"
          >
            {{ unreadCount >= 99 ? "99+" : unreadCount }}
          </div>
          <i :class="currentPage.classes[2]"></i>
        </div>
        <div class="text">{{ currentPage.options.third }}</div>
      </li>
      <li
        @click="handleClickFourthBtn"
        v-if="currentPage.options.fourth"
        :class="{
          disable:
            !hasMessage &&
            (currentPage.name === 'Home') | (currentPage.name === 'Unread')
        }"
      >
        <div><i :class="currentPage.classes[3]"></i></div>
        <div>{{ currentPage.options.fourth }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
// import axios from "axios"
import axios from "../utils/axios";
import { Notify } from "vant";
export default {
  name: "Toolbar",
  data() {
    return {
      Pages: [
        {
          name: "Home",
          path: "/home",
          options: {
            first: "全选",
            second: "全部已读",
            third: "未读消息",
            fourth: "选择"
          },
          secondStyle: {},
          classes: [
            ["iconfont", "icon-quanxuan"],
            ["iconfont", "icon-yidu"],
            ["iconfont", "icon-inform-weidu"],
            ["iconfont", "icon-xuanze"]
          ]
        },
        {
          name: "Search",
          path: "/search",
          options: {
            first: "返回",
            second: "全部已读",
            third: "选择"
          },
          classes: [
            ["iconfont", "icon-fanhui"],
            ["iconfont", "icon-yidu"],
            ["iconfont", "icon-xuanze"]
          ]
        },
        {
          name: "Details",
          path: "/details",
          options: {
            first: "返回",
            second: "删除",
            third: "上一条",
            fourth: "下一条"
          },
          secondStyle: {
            // color: "#f24747"
          },
          classes: [
            ["iconfont", "icon-fanhui"],
            ["iconfont", "icon-shanchu"],
            ["iconfont", "icon-shangyibu"],
            ["iconfont", "icon-xiayibu"]
          ]
        },
        {
          name: "Unread",
          path: "/unread",
          options: {
            first: "全选",
            second: "全部已读",
            third: "全部消息",
            fourth: "选择"
          },
          style: {
            // color: "#f24747"
          },
          classes: [
            ["iconfont", "icon-quanxuan"],
            ["iconfont", "icon-yidu"],
            ["iconfont", "icon-quanbu"],
            ["iconfont", "icon-xuanze"]
          ]
        },
        {
          name: "Login",
          path: "/",
          options: {},
          secondStyle: {},
          classes: []
        },
        {
          name: "Unauth",
          path: "/unauth",
          options: {},
          secondStyle: {},
          classes: []
        }
      ]
    };
  },
  computed: {
    curPageIndex() {
      let curPagePath = this.$route.path.split("/")[1];
      let curPageIndex = this.Pages.findIndex(o => {
        return o.path.includes(curPagePath);
      });
      return curPageIndex;
    },
    currentPage() {
      return this.Pages[this.curPageIndex];
    },
    isSelectMode() {
      return this.$store.state.selectMode;
    },
    searchResultList() {
      return this.$store.state.searchResultList;
    },
    selectedMessageList() {
      return this.$store.state.selectedMessageList;
    },
    selectCount() {
      return this.$store.state.selectedMessageList.length;
    },
    unreadCount() {
      return this.$store.state.unreadCount;
    },
    unreadList() {
      return this.$store.state.unreadList;
    },
    // 上一页下一页相关
    currentMessageIndex() {
      let currenMessageKey = this.$route.params.key;
      let index = this.$store.state.localMessageList.findIndex(element => {
        return element.Key === currenMessageKey;
      });
      return index;
    },
    curIndexInSearchResult() {
      let currenMessageKey = this.$route.params.key;
      let index = this.$store.state.searchResultList.findIndex(element => {
        return element.Key === currenMessageKey;
      });
      return index;
    },
    curIndexInUnreadList() {
      let currenMessageKey = this.$route.params.key;
      let index = this.$store.state.unreadList.findIndex(element => {
        return element.Key === currenMessageKey;
      });
      return index;
    },
    // 首页
    previousMessage() {
      let message;
      let _history = JSON.parse(localStorage.getItem("_history"));
      let prePage = _history[_history.length - 2];
      // 使用搜索结果列表进行上下条跳转
      switch (prePage) {
        case "Home":
          message = this.$store.state.localMessageList[
            this.currentMessageIndex - 1
          ];
          break;
        case "Search":
          message = this.$store.state.searchResultList[
            this.curIndexInSearchResult - 1
          ];
          break;
        case "Unread":
          message = this.$store.state.unreadList[this.curIndexInUnreadList - 1];
          break;
        default:
          break;
      }
      return message;
    },
    nextMessage() {
      let message;
      let _history = JSON.parse(localStorage.getItem("_history"));
      let prePage = _history[_history.length - 2];
      // 使用搜索结果列表进行上下条跳转
      switch (prePage) {
        case "Home":
          message = this.$store.state.localMessageList[
            this.currentMessageIndex + 1
          ];
          break;
        case "Search":
          message = this.$store.state.searchResultList[
            this.curIndexInSearchResult + 1
          ];
          break;
        case "Unread":
          message = this.$store.state.unreadList[this.curIndexInUnreadList + 1];
          break;
        default:
          break;
      }
      // if (this.searchResultList.length !== 0) {
      //   message = this.$store.state.searchResultList[this.curIndexInSearchResult + 1];
      // } else {
      //   message = this.$store.state.localMessageList[this.currentMessageIndex + 1];
      // }
      return message;
    },
    hasUnread() {
      let pageName = this.$route.name;
      if (pageName === "Home") {
        return this.$store.state.localMessageList.some(o => {
          return o.IsRead === 100;
        });
      } else if (pageName === "Search") {
        return this.$store.state.searchResultList.some(o => {
          return o.IsRead === 100;
        });
      } else if (pageName === "Unread") {
        return this.$store.state.unreadList.some(o => {
          return o.IsRead === 100;
        });
      } else {
        return false;
      }
    },
    hasMessage() {
      let pageName = this.$route.name;
      if (pageName === "Home") {
        return this.$store.state.localMessageList.length > 0;
      } else if (pageName === "Search") {
        return this.$store.state.searchResultList.length > 0;
      } else if (pageName === "Unread") {
        return this.$store.state.unreadList.length > 0;
      } else {
        return false;
      }
    }
  },
  methods: {
    backAStep() {
      let currentPageName = this.$route.name;

      // 如果在详情页，搜索结果列表有数据则跳转到搜索页
      if (currentPageName === "Details" && this.searchResultList.length > 0) {
        this.$router.push({ name: "Search" });
        return;
      }
      let _history = JSON.parse(localStorage.getItem("_history"));
      switch (currentPageName) {
        // 如果是在搜索页面则返回首页
        case "Home":
          break;
        case "Search":
          this.$router.push({ name: "Home" });
          break;
        // 如果在详情页，则跳转到首页
        case "Details":
          this.$router.push({ name: _history[_history.length - 2] });
          break;
        case "Unread":
          this.$router.push({ name: "Home" });
          break;
        default:
          this.$router.go(-1);
          break;
      }
    },
    handlePreviousBtn() {
      let key;
      if (this.previousMessage) {
        key = this.previousMessage.Key;
      } else {
        // 主要通知
        Notify({ type: "primary", message: "已经是第一条了" });
        return;
      }
      this.$router.push({
        name: "Details",
        params: { key, message: this.previousMessage }
      });
    },
    handleNextBtn() {
      let key;
      if (this.nextMessage) {
        key = this.nextMessage.Key;
      } else {
        Notify({ type: "primary", message: "已经是最后一条了" });
        return;
      }
      this.$router.push({
        name: "Details",
        params: { key, message: this.nextMessage }
      });
    },
    handleClickFirstBtn() {
      switch (this.curPageIndex) {
        case 0:
          this.selectAll();
          break;
        case 1:
          this.backAStep();
          break;
        case 2:
          this.backAStep();
          break;
        case 3:
          this.selectAll();
          break;
        default:
          break;
      }
    },
    handleClickSecondBtn() {
      switch (this.curPageIndex) {
        case 0:
          this.markReadAll();
          break;
        case 1:
          this.markReadAll();
          break;
        case 2:
          this.deleteAMasseage();
          break;
        case 3:
          this.markReadAll();
          break;
        default:
          break;
      }
    },
    handleClickThirdBtn() {
      switch (this.curPageIndex) {
        case 0:
          this.goToUnreadPage();
          break;
        case 1:
          this.checkOutSelectMode();
          break;
        case 2:
          this.handlePreviousBtn();
          break;
        case 3:
          this.backAStep();
          break;
        default:
          break;
      }
    },
    handleClickFourthBtn() {
      switch (this.curPageIndex) {
        case 0:
          this.checkOutSelectMode();
          break;
        case 1:
          break;
        case 2:
          this.handleNextBtn();
          break;
        case 3:
          this.checkOutSelectMode();
          break;
        default:
          break;
      }
    },
    deleteAMasseage() {
      this.$popconfirm(
        {
          message: "确定删除此消息?"
        },
        () => {
          let key = this.$route.params.key;
          axios
            .delete("/api/Notice/DeleteByIds", {
              data: [key]
            })
            .then(res => {
              if (res.data.Status === 200) {
                Notify({ type: "success", message: "删除成功" });
                this.$store.commit("deleteMessage", [key]);
                this.$router.push({ name: "Home" });
              } else {
                Notify({ type: "warning", message: "删除失败，请稍后重试" });
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      );
    },
    checkOutSelectMode() {
      if (!this.hasMessage) {
        return;
      }
      this.$store.commit("checkOutSelectMode");
    },
    selectAll() {
      if (!this.hasMessage) {
        return;
      }
      let currentPageName = this.$route.name;
      // 进入选择状态
      this.$store.commit("checkOutSelectMode");
      // 全选
      switch (currentPageName) {
        case "Home":
          this.$store.commit("selectAllMessage");
          break;
        case "Search":
          this.$store.commit("selectAllInSearchResult");
          break;
        default:
        case "Unread":
          //  // 全选
          this.$store.commit("selectAllInUnreadList");
          break;
      }
    },
    markReadAll() {
      if (this.unreadCount < 1) {
        return;
      }
      let currentPageName = this.$route.name;
      this.$popconfirm({ message: "确定把全部消息标记为已读？" }, () => {
        switch (currentPageName) {
          case "Home":
            this.$store.commit("selectAllMessage");
            // 标记已读
            this.markRead();
            break;
          case "Search":
            // 全选
            this.$store.commit("selectAllInSearchResult");
            // 标记已读
            this.markRead();
            break;
          case "Unread":
            // 全选
            this.$store.commit("selectAllInUnreadList");
            // 标记已读
            this.markRead();
            break;
          default:
            break;
        }
      });
      // 进入选择状态
      // this.$store.commit("checkOutSelectMode")
    },
    // 标记已读
    markRead() {
      let _messageKeyArray = [];
      this.selectedMessageList.forEach(element => {
        _messageKeyArray.push(element.Key);
      });
      // console.log(_messageKeyArray);
      // let selectedMessageId = this.$store.getters.selectedMessageId
      axios
        .get("/api/Notice/AllRead")
        .then(res => {
          if (res.data.Status === 200) {
            Notify({ type: "success", message: "标记已读成功" });
            // 同步本地列表以更新视图
            let pageName = this.$route.name;
            // console.log(pageName);
            switch (pageName) {
              case "Home":
                // this.$store.commit("markRead", _messageKeyArray);
                this.$store.commit("markReadInUnreadListAll");
                this.$store.commit("markReadAll");
                break;
              case "Search":
                // this.$store.commit("markRead", _messageKeyArray);
                this.$store.commit("markReadInUnreadListAll");
                this.$store.commit("markReadInSearchResultAll");
                this.$store.commit("markReadAll");
                break;
              case "Unread":
                this.$store.commit("markReadInUnreadListAll");
                // this.$store.commit("markRead", _messageKeyArray);
                this.$store.commit("markReadAll");
                break;
              default:
                break;
            }
            // 标记已读完成，自动退出选择模式
            // this.$store.commit("checkOutSelectMode")
          } else {
            Notify({ type: "warning", message: "标记已读失败，请稍后重试" });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    goToUnreadPage() {
      this.$router.push({ name: "Unread" });
    }
  },
  mounted() {}
};
</script>

<style scoped>
#toolbar {
  width: 100vw;
  height: 60px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #eee;
  border-top: 1px solid #ddd;
  user-select: none;
  z-index: 200;
}
ul {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul li {
  /* display: block; */
  height: 100%;
  flex: 1 1 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
ul li:active {
  background-color: #e0e0e0;
}
ul li i {
  font-size: 18px;
}
.icon {
  position: relative;
}
.unread-count {
  position: absolute;
  top: -10px;
  /* left: -18px; */
  right: -18px;
  display: inline-block;
  background-color: rgb(18, 172, 233);
  width: 16px;
  height: 16px;
  padding: 0 2px;
  border-radius: 8px;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  color: #fff;
}
.disable {
  color: #bbb;
}
</style>
