import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import VueRouter from "vue-router";
import store from "./store";

import utils from "./utils";

import { Popconfirm } from "./plugins/popconfirm";
// import { Tip } from "./plugins/tip"
import { Loading } from "./plugins/loading";

import Vant from "vant";
import "vant/lib/index.css";

import "./styles/spacing.css";
import "./styles/position.css";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Popconfirm);
// Vue.use(Tip)
Vue.use(Loading);

Vue.use(Vant);

Vue.prototype.$utils = utils;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
