<template>
  <div id="unread" :class="{ 'select-mode': isSlectMode }">
    <!-- 展示列表 -->
    <ul :id="touchElId">
      <li v-for="message in unreadList" :key="message.Key + Math.random()">
        <Message :theMessage="message" />
      </li>
      <!-- 没有消息 -->
      <div class="no-message-tip" v-if="unreadList.length === 0">
        <span>已经读完了所有消息</span>
      </div>
    </ul>
    <!-- 上拉加载 -->
    <LoadMore :touchElId="touchElId" :loadFunction="pullUpLoad" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Message from "../../components/Message";
import axios from "../../utils/axios";
import LoadMore from "../../components/LoadMore";

import { Notify } from "vant";

export default {
  name: "Unread",
  components: {
    Message,
    LoadMore
  },
  data() {
    return {
      touchElId: "unread-message-list",
      scroll: 0,
      timeOut: null,
      loading: false
    };
  },
  computed: mapState({
    isSlectMode: state => state.selectMode,
    unreadList: state => {
      return state.unreadList;
    },
    currentUnreadPage: state => {
      return state.currentUnreadPage;
    }
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      window.addEventListener("scroll", vm.handleScroll);
    });
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  },
  async mounted() {
    // 清空搜索结果列表
    // 首次加载数据
    this.queryNotice();
  },
  methods: {
    handleScroll() {
      if (this.loading) {
        return;
      }
      const scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      const clen = document.documentElement.clientHeight;
      const of = document.getElementById(this.touchElId).clientHeight;
      const hei = scroll + clen - 120 > of - 10;

      // 上划不加载 距离底部10px加载
      if (this.scroll > scroll || !hei) {
        return;
      }
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.loading = true;
      this.timeOut = setTimeout(async () => {
        await this.pullUpLoad();
        this.loading = false;
        this.scroll = scroll;
      }, 300);
    },
    queryNotice() {
      let options = {
        page: 1,
        pageSize: 1000,
        param: {
          isRead: 100
        }
      };
      axios
        .post("/api/Notice/QueryNotice", options)
        .then(res => {
          this.$store.commit("updateCurrentUnreadPage", res.data.Response.Page);
          this.$store.commit("updateUnreadList", res.data.Response.Data);
        })
        .catch(err => {
          console.log(err);
          Notify({
            type: "warning",
            message: "加载失败，请检查网络连接是否正常"
          });
        });
    },
    async pullUpLoad() {
      let options = {
        page: this.currentUnreadPage + 1,
        pageSize: 1000,
        param: {
          isRead: 100
        }
      };

      axios
        .post("/api/Notice/QueryNotice", options)
        .then(res => {
          let length = res.data.Response.Data.length;
          let currentPage = res.data.Response.Page;
          let data = res.data.Response.Data;
          if (length === 0) {
            Notify({ type: "primary", message: "没有更多结果了" });
            return;
          }
          this.$store.commit("updateCurrentUnreadPage", currentPage);
          this.$store.commit("loadMoreUnread", data);
          Notify({ type: "success", message: `加载了${length}条消息` });
        })
        .catch(err => {
          // 请求失败
          Notify({
            type: "warning",
            message: "加载失败，请检查网络连接是否正常"
          });
          throw new Error(err);
        });
    }
  }
};
</script>

<style scoped>
#unread {
  margin-top: 60px;
  min-height: calc(100vh - 60px);
  transition: margin 0.3s ease;
  width: 100vw;
}
#unread-message-list {
  padding-bottom: 1px;
  scroll-behavior: smooth;
  background-color: #f5f5f5;
  min-height: calc(100vh - 60px);
}
#unread.select-mode {
  margin-top: 0;
}

.no-message-tip {
  height: 150px;
  line-height: 150px;
  text-align: center;
  user-select: none;
}
</style>
