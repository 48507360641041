<template>
  <div id="flight-template">
    <!-- 监控条件 -->
    <div class="block-title blue">
      监控条件
    </div>
    <div class="block-content q-mv-sm">
      <div class="pre">
        航段：{{ messageBody.dep + messageBody.arr }}，监控时间：{{
          formatDate(messageBody.planTime, "dts")
        }}
      </div>
    </div>
    <!-- 预警报告 -->
    <div class="block-title blue q-mt-md">
      预警报告
    </div>
    <table class="property-table full q-mv-md">
      <thead>
        <tr>
          <th style="width: 30px">起飞日期</th>
          <th style="width: 50px;">类型</th>
          <th>关注航班</th>
          <th>对标航班</th>
          <th>差值</th>
          <th>触发</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report, idx) in reports" :key="idx">
          <!-- 起飞日期 -->
          <td
            :rowspan="getRowspanCount(idx, 'flightDate')"
            v-if="getRowspanCount(idx, 'flightDate')"
            class="text-center"
          >
            {{ report.flightDate }}
          </td>
          <!-- 类型 -->
          <td
            :rowspan="getRowspanCount(idx, 'type')"
            v-if="getRowspanCount(idx, 'type')"
            class="text-center"
          >
            {{ report.type }}
          </td>
          <!-- 关注航班 -->
          <td class="text-center">
            <div class="inline-block">
              <div class="text-center inline-block">{{ report.flightNo }}</div>
              <div class="text-right q-mt-xs">
                {{ report.value }}{{ report.unit }}
              </div>
            </div>
          </td>
          <!-- 对标航班 -->
          <td class="text-center">
            <div class="inline-block">
              <div class="text-center inline-block">
                {{ report.compareFlightNo }}
              </div>
              <div class="text-right q-mt-xs">
                {{ report.compareValue }}{{ report.unit }}
              </div>
            </div>
          </td>
          <!-- 差值 -->
          <td class="text-right">{{ report.contrast }}{{ report.unit }}</td>
          <!-- 触发 -->
          <td class="text-center">{{ report.trigger }}</td>
        </tr>
      </tbody>
    </table>
    <!-- 预警阈值配置 -->
    <div class="block-title blue q-mt-md">
      预警规则
    </div>
    <table class="property-table full q-mv-sm">
      <thead>
        <tr>
          <th class="nowrap">类型</th>
          <th>规则描述</th>
          <th class="nowrap">条件编号</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(tpl, idx) in rulesTpl">
          <tr :key="'tpl_1_' + idx" v-if="activeRuleTpl[tpl.attr].startEnable">
            <td
              class="nowrap text-center"
              :rowspan="
                activeRuleTpl[tpl.attr].startEnable &&
                activeRuleTpl[tpl.attr].endEnable
                  ? 2
                  : 1
              "
            >
              {{ tpl.name }}
            </td>
            <td>
              当 关注航班 {{ tpl.name }}
              <span class="tag blue">高于</span> 对标航班
              {{ tpl.name }}
              <span class="tag red">{{
                activeRuleTpl[tpl.attr].start + tpl.unit
              }}</span>
              时, 执行报警.
            </td>
            <td class="nowrap text-center">{{ tpl.index }}-1</td>
          </tr>
          <tr :key="'tpl_2_' + idx" v-if="activeRuleTpl[tpl.attr].endEnable">
            <td
              class="nowrap text-center"
              v-if="!activeRuleTpl[tpl.attr].startEnable"
            >
              {{ tpl.name }}
            </td>
            <td>
              当 关注航班 {{ tpl.name }}
              <span class="tag blue">低于</span> 对标航班 {{ tpl.name }}
              <span class="tag red">{{
                activeRuleTpl[tpl.attr].end + tpl.unit
              }}</span>
              时, 执行报警.
            </td>
            <td class="nowrap text-center">{{ tpl.index }}-2</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { dateUtils } from "../../../utils/common";

export default {
  name: "FlightTemplate",
  props: {
    messageBody: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      rulesTpl: [
        {
          name: "客座率",
          attr: "lf",
          unit: "%",
          index: "Ⅰ"
        },
        {
          name: "上客人数",
          attr: "rb",
          unit: "人",
          index: "Ⅱ"
        },
        {
          name: "最低价",
          attr: "lp",
          unit: "元",
          index: "Ⅲ"
        }
      ],
      activeRuleTpl: {
        lf: {
          start: null,
          end: null,
          startEnable: false,
          endEnable: false
        },
        rb: {
          start: null,
          end: null,
          startEnable: false,
          endEnable: false
        },
        lp: {
          start: null,
          end: null,
          startEnable: false,
          endEnable: false
        }
      },
      reports: [],
      reportFormat: {
        rowspan: {
          flightDate: [
            [0, 5],
            [5, 2]
          ],
          type: [
            [0, 3],
            [3, 1],
            [4, 1],
            [5, 1],
            [6, 1]
          ]
        }
      }
    };
  },
  watch: {
    messageBody: {
      handler() {
        this.analyzeFromMessageBody();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    formatDate(date, type) {
      return dateUtils.formatDate(date, type);
    },
    /**
     * 计算rowspan
     */
    getRowspanCount(idx, attr) {
      const fmtVec = this.reportFormat.rowspan[attr].find(o => o[0] === idx);
      if (fmtVec) {
        return fmtVec[1];
      }
      return null;
    },
    /**
     * 解析消息
     */
    analyzeFromMessageBody() {
      this.genActiveRuleTpl();
      this.genReports();
    },
    /**
     * 生成预警报告
     */
    genReports() {
      let reports = [];
      const source = this.messageBody?.msg ?? [];
      for (const msg of source) {
        const flightDate = dateUtils.formatDate(msg.flightDate, "md");
        for (const tpl of this.rulesTpl) {
          const attr = tpl.attr;
          let tmpReports = [];
          for (const node of msg?.[attr] ?? []) {
            const valueName = `base${attr.charAt(0).toUpperCase() +
              attr.slice(1)}`;
            const compareValueName = `compare${attr.charAt(0).toUpperCase() +
              attr.slice(1)}`;
            const type = tpl.name;
            const flightNo = node?.flightNo;
            const compareFlightNo = node?.compareFlightNo;
            const value = node[valueName];
            const compareValue = node[compareValueName];
            const contrast = node?.[attr];
            // const contrast = value - compareValue;
            let trigger;
            if (node?.start && contrast >= node.start) {
              trigger = tpl.index + "-1";
            } else if (node?.end && contrast <= node.end) {
              trigger = tpl.index + "-2";
            }
            tmpReports.push({
              flightDate,
              type,
              flightNo,
              compareFlightNo,
              value,
              compareValue,
              contrast,
              trigger,
              unit: tpl.unit
            });
          }
          tmpReports.sort((a, b) => (a.trigger > b.trigger ? 1 : -1));
          reports.push(...tmpReports);
        }
      }
      this.reportFormat.rowspan.flightDate = this.genReportsFormat(
        reports,
        "flightDate"
      );
      this.reportFormat.rowspan.type = this.genReportsFormat(reports, "type");
      this.reports = reports;
    },
    /**
     * 生成报表样式控制
     */
    genReportsFormat(reports, attr) {
      let lVal = reports?.[0][attr];
      let lDate = reports?.[0].flightDate;
      let count = 0;
      const rvec = [];
      let anchorIdx = 0;
      for (const ridx in reports) {
        const cVal = reports[ridx][attr];
        const cDate = reports[ridx].flightDate;
        if (cVal === lVal && lDate === cDate) {
          count++;
        } else {
          rvec.push([anchorIdx, count]);
          count = 1;
          lVal = cVal;
          lDate = cDate;
          anchorIdx = parseInt(ridx);
        }
      }
      rvec.push([anchorIdx, count]);
      return rvec;
    },
    /**
     * 生成规则集
     */
    genActiveRuleTpl() {
      const source = this.messageBody?.msg ?? [];
      // const source = this.source;
      for (const msg of source) {
        for (const tpl of this.rulesTpl) {
          const attr = tpl.attr;
          if (msg?.[attr]?.length ?? 0 > 0) {
            if (msg[attr][0]?.start) {
              this.activeRuleTpl[attr].startEnable = true;
              this.activeRuleTpl[attr].start = msg[attr][0].start;
            }
            if (msg[attr][0]?.end) {
              this.activeRuleTpl[attr].endEnable = true;
              this.activeRuleTpl[attr].end = Math.abs(msg[attr][0].end);
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.block-title {
  background-color: rgb(248, 248, 248);
  padding: 3px 6px;
  font-size: 14px;
}

.block-content {
  padding-left: 10px;
  /* margin: 10px 0; */
}

.block-title.gray {
  border-left: 4px solid rgb(78, 78, 78);
}

.block-title.blue {
  border-left: 4px solid #7293ff;
}

.property-table {
  border-collapse: collapse;
  font-size: 12px;
}

.property-table th,
.property-table td {
  border: 1px solid #c2c2c2;
  padding: 4px 4px;
}

.property-table th {
  background-color: #ececec;
}

.property-table.full {
  width: 100%;
}

.property-table.nowrap {
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.tag.blue {
  color: #0d41ea;
}

.tag.red {
  color: #ff2300;
}

.table-condition {
  font-size: 12px;
}
.table-condition th {
  text-align: right;
}

.pre {
  font-size: 13px;
}
</style>
