<template>
  <div
    id="back-top"
    v-if="visible"
  >
    <a href="#">
      <i class="iconfont icon-fanhuidingbu"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "Backtop",
  data () {
    return {
      visible: false
    }
  },
  mounted () {
    this.listenScrollBar()
  },
  methods: {
    listenScrollBar () {
      window.addEventListener("scroll", () => {
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset;
        if (scrollTop >= 600) {
          this.visible = true;
        } else {
          this.visible = false;
        }
      })
    }
  },
}
</script>

<style scoped>
#back-top {
  box-sizing: border-box;
  width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: calc(2rem + 60px);
  right: 2rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
#back-top a {
  display: block;
  width: 100%;
  height: 100%;
}
#back-top a i {
  font-size: 3rem;
  color: rgb(0, 140, 255);
}
</style>