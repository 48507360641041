const scrollPosition = {
  data() {
    return {
      scrollToTop: true,
      scrollToBottom: false
    }
  },
  methods: {
    checkScrollToTop () {
      // console.log("Mixin");
      window.addEventListener("scroll", () => {
        let scrollTop = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
        if (scrollTop <= 0) {
          this.scrollToTop = true;
        } else {
          this.scrollToTop = false;
        }
      })
    },
    checkScrollToBottom () {
      window.addEventListener("scroll", () => {
        let scrollTop = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
        // console.log(scrollTop, document.body.clientHeight, document.body.scrollHeight);
        if (scrollTop + document.body.clientHeight >= document.body.scrollHeight) {
          this.scrollToBottom = true;
        } else {
          this.scrollToBottom = false;
        }
      })
    }
  }
}

export default scrollPosition