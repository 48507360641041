<template>
  <!-- 上拉加载 -->
  <div class="pull-up-load" :style="{ height: height }">
    <div class="loading">
      <i
        class="iconfont icon-jiazai"
        :class="{ rotate: state === 'load-loading' }"
        v-if="state !== 'load-hide'"
      ></i>
      <span v-if="state === 'load-start'">上拉加载...</span>
      <span v-else-if="state === 'load-ready'">松开加载...</span>
      <span v-else-if="state === 'load-loading'">正在加载...</span>
      <span v-else></span>
    </div>
  </div>
</template>

<script>
import scrollPosition from "../mixins/scrollPosition";
import { mapState } from "vuex";
export default {
  name: "LoadMore",
  props: ["touchElId", "loadFunction"],
  mixins: [scrollPosition],
  data() {
    return {
      state: "load-hide",
      height: 0,
      touchStartY: 0,
      firstToBottom: true
    };
  },
  computed: mapState({
    isSlectMode: state => state.selectMode
  }),
  mounted() {
    let touchElId = this.touchElId;

    let touchEl = document.querySelector(`#${touchElId}`);

    this.checkScrollToBottom();
    touchEl.addEventListener("touchstart", () => {
      this.firstToBottom = true;
    });
    touchEl.addEventListener("touchmove", e => {
      // 如果划到底部，记录当前手指的位置
      if (this.scrollToBottom && this.firstToBottom) {
        this.firstToBottom = false;
        this.touchStartY = e.touches[0].pageY;
      }

      if (this.scrollToBottom) {
        let touchEndY = e.touches[0].pageY;

        let height = Math.abs(touchEndY - this.touchStartY);

        if (height >= 20) {
          this.state = "load-start";
        }
        if (height >= 80) {
          this.state = "load-ready";
        }

        this.height = height + "px";
      }
    });
    touchEl.addEventListener("touchend", async () => {
      // 如果在松手时仅仅是到 start 状态，直接回复原样
      if (this.state === "load-start") {
        this.height = 0;
        this.state = "hide";
        return;
      }
      if (this.state !== "load-ready") {
        this.height = 0;
        this.state = "load-hide";
        return;
      }
      // 回弹
      this.height = 80 + "px";
      // 松手时状态变成 loading
      this.state = "load-loading";
      this.loadFunction();
      await this.$utils.delay(600);
      // 请求完成
      this.height = 0;
      this.state = "load-hide";
    });
  }
};
</script>

<style scoped>
.pull-up-load {
  background-color: #eee;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: height 0.1s ease; */
  padding-bottom: 60px;
  user-select: none;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-jiazai {
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.icon-jiazai.rotate {
  animation: 1s rotate infinite linear;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
