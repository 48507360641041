import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // 是否是选择模式
    selectMode: false,
    // 历史搜索列表
    historySearchList: [],
    // 选中的消息列表
    selectedMessageList: [],
    // 本地消息
    localMessageList: [],
    currentPage: 0,
    // 搜索结果
    searchResultList: [],
    // 未读消息列表
    unreadList: [],
    unreadCount: 0,
    currentUnreadPage: 0,
    /**
     * 搜索关键字/标签
     * { type: tag | keyword, content: "xxx"}
     */
    searchObj: {},
    currentSearchResultPage: 0,
    // 是否认证通过
    auth: false
  },
  getters: {
    // 全部消息的
    allMessageKey: state => {
      let _keyList = [];
      state.localMessageList.forEach(item => {
        _keyList.push(item.Key);
      });
      return _keyList;
    },
    // 被选消息
    selectedMessageKeyList: state => {
      let _keyList = [];
      state.selectedMessageList.forEach(item => {
        _keyList.push(item.Key);
      });
      return _keyList;
    }
  },
  mutations: {
    //
    updateAuth(state, isAuth) {
      state.auth = isAuth;
    },
    // 选择模式的切换
    checkOutSelectMode(state) {
      state.selectMode = !state.selectMode;
      // 选择列表置空
      state.selectedMessageList = [];
    },
    // 历史搜索记录
    pushASearch(state, searchKeyword) {
      state.historySearchList.push(searchKeyword);
    },
    clearHistorySearchList(state) {
      state.historySearchList = [];
    },
    initHistorySearchList(state, historySearch) {
      state.historySearchList = historySearch;
    },
    // 选中
    selectAMessage(state, message) {
      state.selectedMessageList.push(message);
    },
    unselectAMessage(state, message) {
      let index = state.selectedMessageList.indexOf(message);
      state.selectedMessageList.splice(index, 1);
    },
    // 全选
    selectAllMessage(state) {
      state.selectedMessageList = [...state.localMessageList];
    },
    selectAllInSearchResult(state) {
      state.selectedMessageList = [...state.searchResultList];
    },
    selectAllInUnreadList(state) {
      state.selectedMessageList = [...state.unreadList];
    },
    unselectAllMessage(state) {
      state.selectedMessageList = [];
    },
    // 首页 消息列表
    updateLocalMessageList(state, newList) {
      state.localMessageList = newList;
    },
    updatecurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    // 未读消息列表
    updateUnreadCount(state, count) {
      state.unreadCount = count;
    },
    updateUnreadList(state, List) {
      state.unreadList = List;
    },
    loadMoreUnread(state, moreUnread) {
      state.unreadList = state.unreadList.concat(moreUnread);
    },
    updateCurrentUnreadPage(state, currentPage) {
      state.currentUnreadPage = currentPage;
    },

    deleteFromUnreadList(state, keyList) {
      let _arr = [];
      state.unreadList.forEach(element => {
        if (!keyList.includes(element.Key)) {
          _arr.push(element);
        }
      });
      state.unreadList = _arr;
      // 清空选择列表
      state.selectedMessageList = [];
    },
    markReadInUnreadList(state, keyList) {
      let _arr = [];
      state.unreadList.forEach(element => {
        keyList.forEach(key => {
          if (element.Key === key) {
            element.IsRead = 200;
          }
        });
        _arr.push(element);
      });
      state.unreadList = _arr;
      // 清空选择列表
      state.selectedMessageList = [];
    },
    markReadInUnreadListAll(state) {
      let _arr = [];
      state.unreadList.forEach(element => {
        element.IsRead = 200;
        _arr.push(element);
      });
      state.unreadList = _arr;
      // 清空选择列表
      state.selectedMessageList = [];
    },
    // 下拉刷新
    loadNewestMessage(state, moreMessage) {
      state.localMessageList = moreMessage.concat(state.localMessageList);
    },
    // 上拉加载更多
    loadMoreMessage(state, moreMessage) {
      state.localMessageList = state.localMessageList.concat(moreMessage);
    },
    deleteMessage(state, keyList) {
      let _arr = [];
      state.localMessageList.forEach(element => {
        if (!keyList.includes(element.Key)) {
          _arr.push(element);
        }
      });
      state.localMessageList = _arr;
      // 清空选择列表
      state.selectedMessageList = [];
    },
    // 标记已读
    markRead(state, keyList) {
      let _arr = [];
      state.localMessageList.forEach(element => {
        keyList.forEach(key => {
          if (element.Key === key) {
            element.IsRead = 200;
          }
        });
        _arr.push(element);
      });
      state.localMessageList = _arr;
      // 清空选择列表
      state.selectedMessageList = [];
    },
    // 全部标记已读
    markReadAll(state) {
      let _arr = [];
      state.localMessageList.forEach(o => {
        o.IsRead = 200;
        _arr.push(o);
      });
      state.localMessageList = _arr;
      state.selectedMessageList = [];
    },
    markReadInSearchResult(state, keyList) {
      let _arr = [];
      state.searchResultList.forEach(element => {
        keyList.forEach(key => {
          if (element.Key === key) {
            element.IsRead = 200;
          }
        });
        _arr.push(element);
      });
      state.searchResultList = _arr;
      // 清空选择列表
      state.selectedMessageList = [];
    },
    markReadInSearchResultAll(state) {
      let _arr = [];
      state.searchResultList.forEach(element => {
        element.IsRead = 200;
        _arr.push(element);
      });
      state.searchResultList = _arr;
      // 清空选择列表
      state.selectedMessageList = [];
    },
    // 更新搜索结果
    updateSearchResultList(state, searchResult) {
      state.searchResultList = searchResult;
    },
    loadMoreSearchResult(state, moreResult) {
      state.searchResultList = state.searchResultList.concat(moreResult);
    },
    // 删除
    deleteFromSearchResult(state, keyList) {
      let _arr = [];
      state.searchResultList.forEach(element => {
        if (!keyList.includes(element.Key)) {
          _arr.push(element);
        }
      });
      state.searchResultList = _arr;
      // 清空选择列表
      state.selectedMessageList = [];
    },
    // 更新当前搜索的关键字或标签
    updateSearchObj(state, searchObj) {
      state.searchObj = searchObj;
    },
    updateSearchPage(state, currentPage) {
      state.currentSearchResultPage = currentPage;
    }
  }
});

export default store;
