var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"toolbar"}},[_c('ul',[(_vm.currentPage.options.first)?_c('li',{class:{
        disable:
          !_vm.hasMessage &&
          (_vm.currentPage.name === 'Home') | (_vm.currentPage.name === 'Unread')
      },on:{"click":_vm.handleClickFirstBtn}},[_c('div',[_c('i',{class:_vm.currentPage.classes[0]})]),_c('div',[_vm._v(_vm._s(_vm.currentPage.options.first))])]):_vm._e(),(_vm.currentPage.options.second)?_c('li',{class:{
        disable:
          _vm.unreadCount < 1 &&
          (_vm.currentPage.name === 'Home')
            | (_vm.currentPage.name === 'Unread')
            | (_vm.currentPage.name === 'Search')
      },style:(_vm.currentPage.secondStyle),on:{"click":_vm.handleClickSecondBtn}},[_c('div',[_c('i',{class:_vm.currentPage.classes[1]})]),_c('div',[_vm._v(_vm._s(_vm.currentPage.options.second))])]):_vm._e(),(_vm.currentPage.options.third)?_c('li',{on:{"click":_vm.handleClickThirdBtn}},[_c('div',{staticClass:"icon"},[(_vm.currentPage.name === 'Home' && _vm.unreadCount > 0)?_c('div',{staticClass:"unread-count"},[_vm._v(" "+_vm._s(_vm.unreadCount >= 99 ? "99+" : _vm.unreadCount)+" ")]):_vm._e(),_c('i',{class:_vm.currentPage.classes[2]})]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.currentPage.options.third))])]):_vm._e(),(_vm.currentPage.options.fourth)?_c('li',{class:{
        disable:
          !_vm.hasMessage &&
          (_vm.currentPage.name === 'Home') | (_vm.currentPage.name === 'Unread')
      },on:{"click":_vm.handleClickFourthBtn}},[_c('div',[_c('i',{class:_vm.currentPage.classes[3]})]),_c('div',[_vm._v(_vm._s(_vm.currentPage.options.fourth))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }