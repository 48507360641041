<template>
  <!-- 下拉刷新 -->
  <div
    id="reflesh"
    :style="{height: height}"
  ><i
      class='iconfont icon-shuaxin'
      :class="{rotate: state === 'reflesh-loading'}"
    ></i>
    <span v-if="state === 'reflesh-start'">下拉刷新...</span>
    <span v-else-if="state === 'reflesh-ready'">松开刷新...</span>
    <span v-else-if="state === 'reflesh-loading'">正在刷新...</span>
    <span v-else></span>
  </div>
</template>

<script>
import scrollPosition from "../mixins/scrollPosition";
import {mapState} from "vuex"
export default {
  name: "Reflesh",
  props: ["touchElId", "loadFunction"],
  mixins: [scrollPosition],
  data () {
    return {
      state: "hide",
      height: 0,
      touchStartY: 0,
      // firstToTop: true
    }
  },
  computed: mapState({
    isSlectMode: state => state.selectMode
  }),
  mounted () {
    let touchElId = this.touchElId;
    let touchEl = document.querySelector(`#${touchElId}`)

    this.checkScrollToTop();
    touchEl.addEventListener("touchstart", (e) => {
      // this.firstToTop = true;
      if (this.scrollToTop) {
        // this.firstToTop = false;
        this.touchStartY = e.touches[0].pageY;
      }
    })
    touchEl.addEventListener("touchmove", (e) => {
      // 如果划到底部，记录当前手指的位置
      // console.log(this.scrollToBottom);
      // console.log(this.scrollToTop);
      // console.log(" reflesh touchmoveeeee");
      if (this.scrollToTop) {
        let touchEndY = e.touches[0].pageY;

        let height = Math.floor(touchEndY - this.touchStartY);

        // height 大于零说明是向下划，此时禁用微信浏览器默认的touch事件
        if (height >= 0) {
          e.cancelable && e.preventDefault();
        }

        if (height >= 20) {
          this.state = "reflesh-start";
        }
        if (height >= 80) {
          this.state = "reflesh-ready";
        }

        this.height = height + "px";
      }
      return;
    })
    touchEl.addEventListener("touchend", async () => {
      if (!this.scrollToTop) {
        return;
      }
      // 如果在松手时仅仅是到 start 状态，直接回复原样
      if (this.state === "reflesh-start") {
        this.height = 0;
        this.state = "reflesh-hide";
        return;
      }
      if (this.state !== "reflesh-ready") {
        this.height = 0;
        this.state = "reflesh-hide";
        return;
      }
      // 回弹
      this.height = 80 + "px";
      // 松手时状态变成 loading
      this.loadFunction();
      await this.$utils.delay(600)
      // 请求完成
      this.height = 0;
      this.state = "reflesh-hide";
    })
  },
}
</script>

<style scoped>
#reflesh {
  width: 100vw;
  height: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-size: 14px;
}

.icon-shuaxin {
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.icon-shuaxin.rotate {
  animation: 1s rotate infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>