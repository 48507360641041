<template>
  <div
    id="popconfirm"
    v-if="visible"
  >
    <div class="content-wrapper">
      <div class="message-box">{{message}}</div>
      <div
        class="btn confirm"
        @click="handleConfirm"
      >{{confirm}}</div>
      <div
        class="btn cancel"
        @click="handleCancel"
      >{{cancel}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popconfirm",
  data () {
    return {
      visible: false,
      message: "",
      cancel: "",
      confirm: "",
      confirmCallback: null,
      cancelCallback: null
    }
  },
  methods: {
    handleCancel () {
      this.$popconfirm.cancel();
    },
    handleConfirm () {
      this.$popconfirm.confirm();
    },
  },
}
</script>

<style scoped>
#popconfirm {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 200;
  animation: 0.3s fadeShow;
  user-select: none;
}
@keyframes fadeShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#popconfirm .content-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 240px;
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: 0.3s slideBottom;
}

@keyframes slideBottom {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: -0;
  }
}
.content-wrapper .message-box {
  height: 100px;
  line-height: 100px;
  flex: 1 0 auto;
}
.content-wrapper .btn {
  width: 100vw;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-top: 1px solid #eee;
}
.btn.confirm {
  color: #f24747;
}
.btn:active {
  background-color: #f2f2f2;
}
</style>