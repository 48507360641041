<template>
  <div>
    <div v-for="(item, index) in vmData" :key="index">
      <div class="block-content q-mv-sm">
        <div class="pre">
          <span>航线 </span>
          <span style="font-weight: 600">{{ item.Dep }}-{{ item.Arr }} </span>
          <span> 发生延误</span>
        </div>
      </div>
      <div
        v-for="(grItem, grIndex) in item.group"
        :key="'gr' + grIndex"
        class="q-mb-lg"
      >
        <div class="block-title blue q-mt-sm">
            <div><span>{{ grItem.HostFlight }}</span>
          <span class="q-ml-md">{{
            formatDate(grItem.HostFlightTakeOff, "MM-DD HH:mm")
          }}</span></div>
          
          <span>发现时间: {{
            formatDate(grItem.ScanTime, "M/D HH:mm")
          }}</span>
        </div>
        <table class="property-table full q-vm-md q-mt-sm">
          <thead>
            <tr>
              <th>起飞</th>
              <th>航班号</th>
              <th>计划时间</th>
              <th>预计时间</th>
              <th>延误(分)</th>
              <!-- <th>发现时间</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dItem, dIndex) in grItem.data" :key="dIndex">
              <td class="text-center">
                {{ formatDate(dItem.FlightDate, "M/D") }}
              </td>
              <td class="text-center">
                {{ dItem.DelayFlightNo }}
              </td>
              <td class="text-center">
                {{ formatDate(dItem.PlanTakeoffTime, "HH:mm") }}
              </td>
              <td class="text-center">
                {{ formatDate(dItem.EstTakeoffTime, "HH:mm") }}
                <span v-if="beforeDay(dItem.PlanTakeoffTime, dItem.EstTakeoffTime) > 0" class="add-day">+{{ beforeDay(dItem.PlanTakeoffTime, dItem.EstTakeoffTime) }}</span>
              </td>
              <td class="text-center">
                {{ dItem.DelayTime }}
              </td>
              <!-- <td class="text-center">
                {{ formatDate(dItem.FlightDate, "MM/DD HH:mm") }}
              </td> -->
            </tr>
          </tbody>
        </table>
        <div style="display: flex;justify-content: flex-end;">
          <span class="url-link q-mt-sm" @click="openCtripUrl(item.Dep, item.Arr, item.FlightDate)">携程</span>
          <span class="url-link q-mt-sm q-ml-md" @click="openLyUrl(item.Dep, item.Arr, item.FlightDate)">同程</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash-es";
import moment from "moment";

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    vmData() {
      try {
        const temp = this.data.map((o) => {
          const flight = _.groupBy(o.DelayFlights, "HostFlight");
          const group = [];
          for (const item in flight) {
            group.push({
              HostFlight: item,
              HostFlightTakeOff: flight[item][0].HostFlightTakeOff,
              ScanTime: o.ScanTime,
              data: flight[item],
            });
          }
          return {
            ...o,
            group,
          };
        });
        return temp;
      } catch (error) {
        return [];
      }
    },
  },
  methods: {
    formatDate(date, type = "d") {
      return moment(date).format(type);
    },
    beforeDay(start, end) {
        const startDay = moment(start).format('YYYY-MM-DD')
        const endDay = moment(end).format('YYYY-MM-DD')
        const day = moment(endDay).diff(moment(startDay), 'days')
        return day
    },
    openCtripUrl(dep, arr, date){
      window.open(`https://flights.ctrip.com/online/list/oneway-${dep}-${arr}0?_=1&depdate=${date}&cabin=Y_S_C_F`, '_blank')
    },
    openLyUrl(dep, arr, date) {
      window.open(`https://www.ly.com/flights/itinerary/oneway/${dep}-${arr}?date=${date}&fromairport=&toairport=&childticket=0,0`, '_blank')
    }
  },
};
</script>

<style scoped>
.block-title {
  background-color: rgb(248, 248, 248);
  padding: 3px 6px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.block-content {
  padding-left: 10px;
  /* margin: 10px 0; */
}

.block-title.gray {
  border-left: 4px solid rgb(78, 78, 78);
}

.block-title.blue {
  border-left: 4px solid #7293ff;
}

.property-table {
  border-collapse: collapse;
  font-size: 12px;
}

.property-table th,
.property-table td {
  border: 1px solid #c2c2c2;
  padding: 6px 4px;
}

.property-table th {
  background-color: #ececec;
  padding: 10px 4px;
}

.property-table.full {
  width: 100%;
}

.property-table.nowrap {
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.tag.blue {
  color: #0d41ea;
}

.tag.red {
  color: #ff2300;
}

.table-condition {
  font-size: 12px;
}
.table-condition th {
  text-align: right;
}

.pre {
  font-size: 16px;
  font-weight: 500;
}
.before-time {
  color: #9e9e9e;
  text-decoration: line-through;
}
.after-time {
  color: #409eff;
}
.add-day {
    color: #ff2300;
    position: absolute;
    margin-left: 2px;
    font-size: 12px;
}
.url-link{
  color: #409eff;
}
</style>