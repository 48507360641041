<template>
  <div id="home" :class="{ 'select-mode': isSlectMode }">
    <!-- 下拉刷新 -->
    <Reflesh :touchElId="touchElId" :loadFunction="dropDownRefresh" />
    <!-- 展示列表 -->
    <ul id="message-list">
      <li
        v-for="message in localMessageList"
        :key="message.Key + Math.random()"
      >
        <Message :theMessage="message" />
      </li>
      <!-- 没有消息 -->
      <div class="no-message-tip" v-if="localMessageList.length === 0">
        <span>还没有信息，请下拉刷新或点击</span>
        <span class="load-more" @click="pullUpLoad">加载更多</span>
      </div>
    </ul>
    <!-- 上拉加载 -->
    <LoadMore :touchElId="touchElId" :loadFunction="pullUpLoad" />
  </div>
</template>

<script>
import Message from "../../components/Message";
import moment from "moment";
import axios from "../../utils/axios";
import LoadMore from "../../components/LoadMore";
import Reflesh from "../../components/Reflesh";
import { Notify } from "vant";

import { mapState } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      touchElId: "message-list",
      scroll: 0,
      timeOut: null,
      loading: false
    };
  },
  computed: mapState({
    auth: state => state.auth,
    isSlectMode: state => state.selectMode,
    localMessageList: state => {
      return state.localMessageList;
    },
    currentPage: state => {
      return state.currentPage;
    }
  }),
  components: {
    Message,
    LoadMore,
    Reflesh
  },
  updated() {
    // 未读数量
    this.getUnreadCount();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      window.addEventListener("scroll", vm.handleScroll);
    });
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  },
  async mounted() {
    this.$store.commit("updateSearchObj", { content: "搜索" });
    // 未读数量
    this.getUnreadCount();
    // 清空搜索结果列表
    this.$store.commit("updateSearchResultList", []);
    // 首次加载数据
    if (this.localMessageList.length === 0) {
      this.queryNotice();
    }
  },
  methods: {
    handleScroll() {
      if (this.loading) {
        return;
      }
      const scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      const clen = document.documentElement.clientHeight;
      const of = document.getElementById("message-list").clientHeight;
      const hei = scroll + clen - 120 > of - 10;
      // 上划不加载 距离底部10px加载
      if (this.scroll > scroll || !hei) {
        return;
      }
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.loading = true;
      this.timeOut = setTimeout(async () => {
        await this.pullUpLoad();
        this.loading = false;
        this.scroll = scroll;
      }, 300);
    },
    async queryNotice() {
      let options = { page: 1, pageSize: 20 };
      axios
        .post("/api/Notice/QueryNotice", options)
        .then(res => {
          // console.log(res);
          this.$store.commit("updatecurrentPage", res.data.Response.Page);
          this.$store.commit("updateLocalMessageList", res.data.Response.Data);
          this.$store.commit("updateAuth", true);
        })
        .catch(err => {
          // 首页数据请求失败
          Notify({
            type: "warning",
            message: "加载失败，请检查网络连接是否正常"
          });
          throw new Error(err);
        });
    },
    async dropDownRefresh() {
      let options = { page: 1, pageSize: 20 };
      let res;
      try {
        res = await axios.post("/api/Notice/QueryNotice", options);
      } catch (e) {
        console.log(e);
        Notify({
          type: "warning",
          message: "刷新失败，请检查网络连接是否正常"
        });
        return;
      }
      // 请求失败
      if (res.data.Status !== 200) {
        Notify({
          type: "warning",
          message: "刷新失败，请检查网络连接是否正常"
        });
      }
      let data = res.data.Response.Data;
      // let response = res.data.Response;
      // 如果本地还没有消息，则直接把数据赋值给本地列表
      if (this.localMessageList.length === 0) {
        this.$store.commit("loadNewestMessage", data);
      }
      // data 为空 没有新消息
      if (data.length === 0) {
        Notify({ type: "primary", message: "暂时没有新的消息了" });
        return;
      }
      // 记录当前页数
      // this.currentPage = res.data.Response.Page;
      this.$store.commit("updatecurrentPage", res.data.Response.Page);
      // 过滤消息，选出比本地最新一条还新的消息
      let newMessageList = [];

      let localNewestMessage = this.localMessageList[0];
      let localNewestTime = moment(localNewestMessage.NoticeTime);

      data.forEach(element => {
        let time = moment(element.NoticeTime);
        if (time.isAfter(localNewestTime)) {
          newMessageList.push(element);
        }
      });
      if (newMessageList.length === 0) {
        Notify({ type: "primary", message: "暂时没有新的消息了" });
        return;
      }
      // 同步本地消息列表
      this.$store.commit("loadNewestMessage", newMessageList);
      Notify({
        type: "success",
        message: `刷新成功,加载${newMessageList.length}条新消息`
      });
    },
    async pullUpLoad() {
      // console.log("pull up load");
      let options = { page: this.currentPage + 1, pageSize: 20 };
      let res;
      try {
        res = await axios.post("/api/Notice/QueryNotice", options);
      } catch (e) {
        console.log(e);
        Notify({
          type: "warning",
          message: "加载失败，请检查网络连接是否正常"
        });
        return;
      }
      // 请求失败
      if (res.data.Status !== 200) {
        Notify({
          type: "warning",
          message: "加载失败，请检查网络连接是否正常"
        });
      }
      // 如果本地还没有消息，则直接把数据赋值给本地列表
      let data = res.data.Response.Data;
      // let response = res.data.Response;
      // console.log(response);
      if (this.localMessageList.length === 0) {
        this.$store.commit("loadMoreMessage", data);
      }
      if (data.length === 0) {
        Notify({ type: "primary", message: "没有更多消息了" });
        return;
      }
      // 记录当前页数
      // this.currentPage = res.data.Response.Page;
      this.$store.commit("updatecurrentPage", res.data.Response.Page);
      // 过滤重复数据
      let localKeyList = this.$store.getters.allMessageKey;

      // console.log("data key");
      // data.forEach(o => {
      //   console.log(o.Key);
      // })
      // console.log("locallist key", localKeyList);

      let newList = [...this.localMessageList];
      data.forEach(o => {
        let hasExit = localKeyList.some(key => {
          return o.Key === key;
        });
        if (!hasExit) {
          newList.push(o);
        }
      });
      if (newList.length === 0) {
        Notify({ type: "primary", message: "没有更多消息了" });
        return;
      }
      // // 排序
      newList.sort((a, b) => {
        let aTime = new Date(a.NoticeTime).getTime();
        let bTime = new Date(b.NoticeTime).getTime();
        return bTime - aTime;
      });

      // // 同步本地列表
      this.$store.commit("updateLocalMessageList", newList);
      Notify({
        type: "success",
        message: `加载了${newList.length - localKeyList.length}条消息`
      });
      // console.log(this.currentPage);
    },
    async getUnreadCount() {
      // await this.$utils.delay(1200)
      let auth = this.$store.state.auth;
      if (!auth) {
        return;
      }

      let options = {
        param: {
          isRead: 100
        }
      };
      axios
        .post("/api/Notice/QueryNotice", options)
        .then(res => {
          this.$store.commit("updateUnreadCount", res.data.Response.DataCount);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style>
#home {
  margin-top: 60px;
  min-height: calc(100vh - 60px);
  transition: margin 0.3s ease;
}
#message-list {
  padding-bottom: 1px;
  scroll-behavior: smooth;
  background-color: #f5f5f5;
  min-height: calc(100vh - 60px);
  /* background-color: royalblue; */
}
#home.select-mode {
  margin-top: 0;
}
.no-message-tip {
  height: 150px;
  line-height: 150px;
  /* background-color: #fff; */
  text-align: center;
  user-select: none;
}
.load-more {
  color: rgb(0, 140, 255);
}
</style>
