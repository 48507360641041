<template>
  <div
    id="options-panel"
    v-if="isSelectMode"
  >
    <ul>
      <li
        class="btn select-all"
        @click="toggleAllSelect"
      >
        <div><i class="iconfont icon-quanxuan"></i></div>
        <div>{{isSelectAll ? "取消全选" : "全选"}}</div>
      </li>
      <li
        class="btn mark-read"
        @click="markRead"
        :aria-disabled="true"
        :class="{disable: this.unselect, 'no-unread': !this.atLeastAUnread}"
      >
        <div><i class="iconfont icon-yidu"></i></div>
        <div>标记已读</div>
      </li>
      <li
        @click="handleDelete"
        class="btn delete"
        :class="{disable: this.unselect}"
      >
        <div><i class="iconfont icon-shanchu"></i></div>
        <div>删除</div>
      </li>
      <li
        @click="checkOutSelectMode"
        class="btn exit-mode"
      >
        <div><i class="iconfont icon-chexiao"></i></div>
        <div>撤销</div>
      </li>
    </ul>
  </div>
</template>

<script>
// import axios from 'axios';
import axios from "../utils/axios"
import {Notify} from 'vant';

export default {
  name: "SelectPanel",
  data () {
    return {
      // haveNoSelect: {
      //   color: "#bbb"
      // }
    }
  },
  computed: {
    isSelectMode () {
      return this.$store.state.selectMode
    },
    isSelectAll () {
      if (this.$route.name === "Home") {
        return this.$store.state.localMessageList.length === this.$store.state.selectedMessageList.length;
      }
      return this.$store.state.searchResultList.length === this.$store.state.selectedMessageList.length;
    },
    selectCount () {
      return this.$store.state.selectedMessageList.length;
    },
    selectedMessageList () {
      return this.$store.state.selectedMessageList;
    },
    unselect () {
      if (this.$store.state.selectedMessageList.length === 0) {
        return true;
      }
      return false;
    },
    atLeastAUnread () {
      // 至少有一个未读
      let isAtLeastAUnread = this.selectedMessageList.some(element => {
        return element.IsRead === 100;
      });
      return isAtLeastAUnread;
    }
  },
  methods: {
    // 全选/取消全选
    toggleAllSelect () {
      if (!this.isSelectAll) {
        // 判断在哪个页面，在首页则全选首页的列表，否则全选搜索页列表
        if (this.$route.name === "Home") {
          this.$store.commit("selectAllMessage");
        } else {
          this.$store.commit("selectAllInSearchResult");
        }
      } else {
        this.$store.commit("unselectAllMessage");
      }
    },
    // 标记已读
    markRead () {
      if (!this.atLeastAUnread) {
        return;
      }
      let _messageKeyArray = [];
      this.selectedMessageList.forEach(element => {
        _messageKeyArray.push(element.Key);
      });
      if (this.selectCount < 1) {
        return;
      }

      axios.post("/api/Notice/Read", _messageKeyArray).then(res => {
        if (res.data.Status === 200) {
          Notify({type: 'success', message: "标记已读成功"});
          // 同步本地列表以更新视图
          let pageName = this.$route.name;
          switch (pageName) {
            case "Home":
              this.$store.commit("markRead", _messageKeyArray);
              this.$store.commit("markReadInUnreadList", _messageKeyArray)
              break;
            case "Search":
              this.$store.commit("markReadInSearchResult", _messageKeyArray)
              this.$store.commit("markRead", _messageKeyArray);
              this.$store.commit("markReadInUnreadList", _messageKeyArray)
              break;
            case "Unread":
              // console.log("tong bu");
              this.$store.commit("markRead", _messageKeyArray);
              this.$store.commit("markReadInUnreadList", _messageKeyArray)
              break;
            default:
              break;
          }
          // 标记已读完成，自动退出选择模式
          this.$store.commit("checkOutSelectMode")
        } else {
          Notify({type: 'warning', message: "标记已读失败，请稍后重试"});
        }
      }).catch(err => {
        console.log(err);
        Notify({type: 'warning', message: "标记已读失败，请稍后重试"});
      })
    },
    // 删除
    handleDelete () {
      if (this.selectCount < 1) {
        return;
      }
      this.$popconfirm({
        message: `确定删除${this.$store.state.selectedMessageList.length}条消息？`,
        cancel: "取消",
        confirm: "删除"
      }, this.deleteMessage)
    },
    async deleteMessage () {
      let _messageKeyArray = [];
      this.selectedMessageList.forEach(element => {
        _messageKeyArray.push(element.Key);
      });
      // let selectedMessageKeyList = this.$store.getters.selectedMessageKeyList

      let res;
      try {
        res = await axios.delete("/api/Notice/DeleteByIds", {
          data: _messageKeyArray
        });
      } catch (e) {
        console.log(e);
      }
      // 成功
      if (res.data.Status === 200) {
        Notify({type: 'success', message: "删除成功"});
        // 同时删除本地的列表保证视图更新
        let pageName = this.$route.name;
        switch (pageName) {
          case "Home":
            this.$store.commit("deleteMessage", _messageKeyArray);
            this.$store.commit("deleteFromUnreadList", _messageKeyArray)
            break;
          case "Search":
            this.$store.commit("deleteMessage", _messageKeyArray);
            this.$store.commit("deleteFromSearchResult", _messageKeyArray);
            this.$store.commit("deleteFromUnreadList", _messageKeyArray)
            break;
          case "Unread":
            this.$store.commit("deleteMessage", _messageKeyArray);
            this.$store.commit("deleteFromUnreadList", _messageKeyArray)
            break;
          default:
            break;
        }
        // 删除操作完成，自动退出选择模式
        this.$store.commit("checkOutSelectMode")
      } else {
        Notify({type: 'warning', message: "标记已读失败，请稍后重试"});
      }
    },
    checkOutSelectMode () {
      this.$store.commit("checkOutSelectMode")
    },
  },
}
</script>

<style scoped>
#options-panel {
  width: 100vw;
  height: 60px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #eee;
  border-top: 1px solid #ddd;
  user-select: none;
  z-index: 200;
}
ul {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul li {
  /* display: block; */
  height: 100%;
  flex: 1 1 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
ul li i {
  font-size: 18px;
}
ul li.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #f24747; */
}
.btn:active {
  background-color: #e4e4e4;
}
.disable {
  color: #bbb;
}
.no-unread {
  color: #bbb;
}
</style>