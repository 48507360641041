import moment from "moment"
moment.locale("zh_CN"); 
function dateFromNow(date) {
  let _dateFromNow;
  // 现在的年份
  let nowYear = moment().get("year")
  // 消息发送的年份
  let pushYear = moment(date).get("year")
  // 推送时间 到 现在是否是两天以内
  let isWithinTwoday = moment(date).isBetween(moment().subtract(2, "day"), moment());
  // 是一天之内
  let isToday = moment(date).isBetween(moment().subtract(1, "day"), moment());
  if(isToday) {
    _dateFromNow = moment(date).fromNow();
  } else if(isWithinTwoday) {
    // 两天之内
    _dateFromNow = moment(date).calendar();
  } else if (nowYear !== pushYear) {
    // 如果不是今年
    _dateFromNow = moment(date).format("YYYY-MM-DD");
  } else {
    // 是今年
    _dateFromNow = moment(date).format("MM-DD");
  }
  return _dateFromNow
}

export default dateFromNow